/* eslint-disable react/no-unknown-property */
import React from "react";
import { CCard, CCardBody } from "@coreui/react";

const UserActivity = () => {
  return (
    <CCard className="mb-4">
      <CCardBody>
        <h2>Activity Feed</h2>
        <ol className="activity-feed">
          <li className="feed-item">
            <time className="date" datetime="9-25">
              Sep 25
            </time>
            <span className="text">
              Responded to need <a href="single-need.php">“Volunteer opportunity”</a>
            </span>
          </li>
          <li className="feed-item">
            <time className="date" datetime="9-24">
              Sep 24
            </time>
            <span className="text">Added an interest “Volunteer Activities”</span>
          </li>
          <li className="feed-item">
            <time className="date" datetime="9-23">
              Sep 23
            </time>
            <span className="text">
              Joined the group <a href="single-group.php">“Boardsmanship Forum”</a>
            </span>
          </li>
          <li className="feed-item">
            <time className="date" datetime="9-21">
              Sep 21
            </time>
            <span className="text">
              Responded to need <a href="single-need.php">“In-Kind Opportunity”</a>
            </span>
          </li>
          <li className="feed-item">
            <time className="date" datetime="9-18">
              Sep 18
            </time>
            <span className="text">
              Created need <a href="single-need.php">“Volunteer Opportunity”</a>
            </span>
          </li>
          <li className="feed-item">
            <time className="date" datetime="9-17">
              Sep 17
            </time>
            <span className="text">
              Attending the event <a href="single-event.php">“Some New Event”</a>
            </span>
          </li>
        </ol>
      </CCardBody>
    </CCard>
  );
};

export default UserActivity;
