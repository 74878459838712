import React, { Component, Suspense } from "react";
import { Routes, BrowserRouter as Router, Route, useNavigate, Navigate } from "react-router-dom";
import "./scss/style.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Dashboard from "./views/dashboard/Dashboard";
import UserTypeManagement from "./views/manage-user-type/UserTypeManagement";
import CreateUserTypeForm from "./views/manage-user-type/CreateUserTypeForm";
import UserManagement from "./views/manage-user/UserManagement";
import CreateUserForm from "./views/manage-user/CreateUserForm";

import UserActivity from "./views/user-activity/UserActivity";
import UserProfile from "./views/user-profile/UserProfile";
import { Toaster } from "react-hot-toast";
import CampaignManagement from "./views/manage-campaign/CampaignManagement";
import CreateCampaignForm from "./views/manage-campaign/CreateCampaignForm";
import CampaignManagementFileUpload from "./views/manage-campaign/CampaignManagementFileUpload";
import Campaign from "./views/manage-campaign/Campaign";
import CampaignReport from "./views/manage-report/CampaignReport";
import FileReport from "./views/manage-report/FileReport";
import ApplyFilters from "./components/Filters/ApplyFilters";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
// http://localhost:3000/manage-route
class App extends Component {
  render() {
    const queryClient = new QueryClient();
    return (
      <QueryClientProvider client={queryClient}>
        <Router>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/" element={<Navigate to="/dashboard" />} />
              {/* <Route path="/" element={<Navigate to="/login" />} /> */}
              <Route path="/login" element={<Login />} />
              <Route path="/*" element={<DefaultLayout />}>
                <Route path="dashboard" element={<Dashboard />} />

                <Route path="manage-user-type" element={<UserTypeManagement />} />
                <Route path="create-user-type" element={<CreateUserTypeForm />} />
                <Route path="edit-user-type/:id" element={<CreateUserTypeForm />} />

                <Route path="manage-user" element={<UserManagement />} />
                <Route path="create-user" element={<CreateUserForm />} />
                <Route path="edit-user/:id" element={<CreateUserForm />} />

                <Route path="projects/:projectName/:id" element={<Campaign />} />
                <Route path="projects" element={<CampaignManagement />} />
                <Route path="create-campaign" element={<CreateCampaignForm />} />
                <Route path="upload-file" element={<CampaignManagementFileUpload />} />
                <Route path="apply-filters/:id" element={<ApplyFilters />} />

                <Route path="report/campaign/:id" element={<CampaignReport />} />
                <Route path="report/:id" element={<FileReport />} />

                <Route path="user-activity" element={<UserActivity />} />

                <Route path="profile" element={<UserProfile />} />
              </Route>
              {/* Add other routes here */}
            </Routes>
          </Suspense>
          <Toaster />
        </Router>
      </QueryClientProvider>
    );
  }
}

export default App;
