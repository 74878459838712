import ApiClient from "./interceptor";

const createUser = async (input) => {
  return ApiClient.post("/users", input);
};

const deleteUser = async (id) => {
  return ApiClient.delete("/users/" + id);
};

const getAllUser = async () => {
  return ApiClient.get("/users");
};

const getUserById = async (id) => {
  return ApiClient.get("/users/" + id);
};

const updateUser = async ({ id, data }) => {
  return ApiClient.patch("/users/" + id, data);
};

const searchUser = async (parmas) => {
  return ApiClient.get("/users/search", { params: parmas });
};

export { createUser, deleteUser, getAllUser, getUserById, updateUser, searchUser };
