import React, { useRef, useState, useEffect } from "react";
import { getUserTypeList } from "../../services/user-type.services";
import toast from "react-hot-toast";
import {
  CAvatar,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CToaster,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilPeople, cilPencil, cilPlus } from "@coreui/icons";
import CustomPagination from "../../components/CustomPagination";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import avatar1 from "src/assets/images/avatars/1.jpg";
import avatar2 from "src/assets/images/avatars/2.jpg";
import avatar3 from "src/assets/images/avatars/3.jpg";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

const UserTypeManagementTable = (props) => {
  const toaster = useRef();
  const [toast, addToast] = useState("");
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getUserTypeItem(currentPage);
  }, []);

  const getUserTypeItem = (page) => {
    setIsLoading(true);
    getUserTypeList({ limit, page })
      .then(async (response) => {
        setIsLoading(false);
        setCount(response.count);
        setData(response.data);
        setData(response.data);
        const totalPages = Math.ceil(response.count / limit);
        setTotalPages(totalPages);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getUserTypeItem(newPage);
  };

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                User Type Management
              </h4>
            </CCol>
            <CCol sm={7} className="d-none d-md-block">
              <div className="d-flex justify-content-end">
                <Link to="/create-user-type">
                  <CButton disabled color="success">
                    <CIcon icon={cilPlus} />
                    &nbsp; Create
                  </CButton>
                </Link>
              </div>
            </CCol>
          </CRow>
          <br />
          <CTable align="middle" className="mb-0 border" hover responsive>
            <CTableHead color="light">
              <CTableRow>
                {/* <CTableHeaderCell className="text-center">
                  <CIcon icon={cilPeople} />
                </CTableHeaderCell> */}
                <CTableHeaderCell>Position Name</CTableHeaderCell>
                <CTableHeaderCell>User Type</CTableHeaderCell>
                <CTableHeaderCell>Permission</CTableHeaderCell>
                <CTableHeaderCell>Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {/* {
              isLoading? 
              <CTableRow className="text-center">
                <CTableDataCell colspan="5">
                Loading...
                </CTableDataCell>
              </CTableRow>:
              data.map((item, index) => (
                <CTableRow v-for="item in tableItems" key={index}>
                  <CTableDataCell>
                    <div>{item.name}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <div>{item.type}</div>
                  </CTableDataCell>
                  <CTableDataCell>
                  {
                    Object.entries(item.permission).map(([key, value]) => (
                      <div key={key}>
                        {key}: {value.toString()}
                      </div>
                    ))
                  }
                  </CTableDataCell>
                  <CTableDataCell>
                  <Link to={`/edit-user-type/${item._id}`}>
                  <CButton color="secondary" style={{color:'white'}}>
                          <FaEdit />
                        </CButton>
                  </Link>
                      
               
                  </CTableDataCell>
                </CTableRow>
              ))} */}

              <CTableRow>
                <CTableDataCell>
                  <div>User</div>
                </CTableDataCell>
                <CTableDataCell>
                  <div>User</div>
                </CTableDataCell>
                <CTableDataCell>
                  <div>
                    {" "}
                    <p>manage_user_type: false</p> <p>manage_user: false</p>
                  </div>
                </CTableDataCell>
                <CTableDataCell>
                  <Link>
                    <CButton color="secondary" style={{ color: "white" }}>
                      <FaEdit />
                    </CButton>
                  </Link>
                </CTableDataCell>
              </CTableRow>

              <CTableRow>
                <CTableDataCell>
                  <div>Admin</div>
                </CTableDataCell>
                <CTableDataCell>
                  <div>Admin</div>
                </CTableDataCell>
                <CTableDataCell>
                  <div>
                    {" "}
                    <p>manage_user_type: true</p> <p>manage_user: true</p>
                  </div>
                </CTableDataCell>
                <CTableDataCell>
                  <Link>
                    <CButton color="secondary" style={{ color: "white" }}>
                      <FaEdit />
                    </CButton>
                  </Link>
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
          <br />
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </CCardBody>
      </CCard>
      <DeleteModal
        visible={visibleDeleteModal}
        setVisibleDeleteModal={setVisibleDeleteModal}
        addToast={addToast}
      />
      <CToaster ref={toaster} push={toast} placement="top-end" />
    </>
  );
};

export default UserTypeManagementTable;
