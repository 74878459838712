import { FilterTypes } from "../commontypes";
import { removeHiddenRows } from "./commonHelper";

const handleFilterSelectedColumnsDuplicateData = (
  columns,
  rows,
  selectedColumns,
  hiddenData,
  setHiddenData,
  filterTypes,
) => {
  let obj = {};
  let newRows = [];
  const _selectedColumns = selectedColumns[filterTypes];
  const _hiddenData = { ...hiddenData };
  _hiddenData[FilterTypes.IP_ADDRESS_CHECK] = _hiddenData[FilterTypes.IP_ADDRESS_CHECK] || [];
  for (let i = 0; i < _selectedColumns.length; i++) {
    newRows = rows.filter((row, index) => {
      obj[row[columns[_selectedColumns[i]]]] = false;
      for (let j = index + 1; j < rows.length; j++) {
        if (obj[row[columns[_selectedColumns[j]]]]) return false;
        else {
          if (row[columns[_selectedColumns[i]]] === rows[j][columns[_selectedColumns[i]]]) {
            obj[row[columns[_selectedColumns[i]]]] = true;
            _hiddenData[FilterTypes.IP_ADDRESS_CHECK].push(row);
            return false;
          }
        }
      }
      if (obj[row[columns[_selectedColumns[i]]]]) {
        _hiddenData[FilterTypes.IP_ADDRESS_CHECK].push(row);
        return false;
      } else return true;
    });
    obj = {};
  }
  setHiddenData(_hiddenData);
  newRows = removeHiddenRows(rows, _hiddenData);
  return { _hiddenData, result: newRows };
};

export { handleFilterSelectedColumnsDuplicateData };
