import * as React from "react";
const GoBackSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} fill="none" {...props}>
    <path
      fill="#7E7D7D"
      d="M25.5 16.5H14.115l4.95-4.935a1.507 1.507 0 0 0-2.13-2.13l-7.5 7.5c-.136.143-.244.31-.315.495a1.5 1.5 0 0 0 0 1.14 1.5 1.5 0 0 0 .315.495l7.5 7.5a1.498 1.498 0 0 0 2.13 0 1.5 1.5 0 0 0 0-2.13l-4.95-4.935H25.5a1.5 1.5 0 0 0 0-3Z"
    />
  </svg>
);
export default GoBackSvg;
