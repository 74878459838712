import React, { useState, useRef } from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
} from "@coreui/react";

const DeleteModal = ({
  visibleDeleteModal,
  setVisibleDeleteModal,
  submitDelete,
  message = "Are you sure want to delete this?",
  btnText = "Delete",
}) => {
  const closeModal = () => {
    setVisibleDeleteModal(false);
  };
  const deleteFn = () => {
    // addToast(exampleToast);
    setVisibleDeleteModal(false);
    submitDelete();
  };
  return (
    <div>
      <CModal
        backdrop="static"
        visible={visibleDeleteModal}
        onClose={() => {
          closeModal();
        }}>
        <CModalHeader onClose={() => closeModal()} className="border-0 px-4">
          <CModalTitle>Delete</CModalTitle>
        </CModalHeader>
        <CModalBody className="px-4 py-0">{message}</CModalBody>
        <CModalFooter className="border-0 px-4 flex-end">
          <CButton color="secondary" className="text-white" onClick={() => closeModal()}>
            Close
          </CButton>
          <CButton color="danger" className="text-white" onClick={() => deleteFn()}>
            {btnText}
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default DeleteModal;
