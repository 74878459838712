import React, { useState, useEffect } from "react";

import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { CChart, CChartLine } from "@coreui/react-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils";
import CIcon from "@coreui/icons-react";
import avatar1 from "src/assets/images/avatars/1.jpg";
import avatar2 from "src/assets/images/avatars/2.jpg";
import avatar3 from "src/assets/images/avatars/3.jpg";
import avatar4 from "src/assets/images/avatars/4.jpg";
import avatar5 from "src/assets/images/avatars/5.jpg";
import avatar6 from "src/assets/images/avatars/6.jpg";
import marketing from "src/assets/images/dashboard/marketing.png";
import analysis from "src/assets/images/dashboard/analysis.png";
import people from "src/assets/images/dashboard/people.png";
import folder from "src/assets/images/dashboard/folder.png";
import map from "src/assets/images/map.png";

import WidgetsBrand from "../widgets/WidgetsBrand";
import WidgetsDropdown from "../widgets/WidgetsDropdown";
import WidgetsF from "../widgets/WidgetsF";
import WidgetsC from "./WidgetsC";
import WidgetsD from "./WidgetsD";
import MegaPhoneSvg from "src/assets/svg/MegaPhoneSvg";
import DatabaseSvg from "src/assets/svg/DatabaseSvg";
import ProfileSvg from "src/assets/svg/ProfileSvg";
import UploadFileSvg from "src/assets/svg/UploadFileSvg";
import ExcelOutlineSvg from "src/assets/svg/ExcelOutlineSvg";
import { getDashboardReport } from "src/services/report.services";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import Campaign from "../manage-campaign/Campaign";
import { useNavigate } from "react-router";
import DashboardCampaignReport from "./DashboardCampaignReport";

const Dashboard = () => {
  const [totalCampaignCount, setTotalCampaignCount] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [graphArray, setGraphArray] = useState(0);
  const [recentFiles, setRecentFiles] = useState([]);
  const [projectData, setProjectData] = useState(["Select Project"]);

  const {
    data: dashboardReport,

    onError: isUserDataError,
  } = useQuery(["reports"], () => getDashboardReport(), {
    // staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (dashboardReport && dashboardReport?.data) {
      const dashboardReportData = dashboardReport.data;
      setTotalCampaignCount(dashboardReportData.totalCampaignCount);
      setTotalDataCount(dashboardReportData.totalDataCount);
      setTotalFiles(dashboardReportData.totalFiles);
      setTotalUsers(dashboardReportData.totalUsers);
      setGraphArray(dashboardReportData.graphArray);
      setRecentFiles(dashboardReportData.recentFiles);

      const projectList = dashboardReportData.allProject ? dashboardReportData.allProject : [];
      if (projectList.length > 0) {
        let ProjectListArray = [{ label: "Select Project", value: "" }];
        projectList.forEach((element) => {
          let obj = { label: element.name, value: element._id };
          ProjectListArray.push(obj);
        });
        setProjectData(ProjectListArray);
      }
    }
  }, [dashboardReport]);

  const handleFileOpen = (item) => [navigate(`/apply-filters/${item._id}`)];

  return (
    <>
      <div className="header-card-wrapper mb-4">
        <div className="header-card rounded-3 d-card-one campaign">
          <div className="header-card-text-box ms-4">
            <div className="header-card-text-total">Total Project</div>
            <div className="header-card-text-count">{totalCampaignCount}</div>
          </div>
          <div className="header-card-img-box">
            <img src={marketing} alt="marketing" />
          </div>
        </div>
        <div className="header-card rounded-3 d-card-two data">
          <div className="header-card-text-box ms-4">
            <div className="header-card-text-total">Total Project Data</div>
            <div className="header-card-text-count">{totalDataCount}</div>
          </div>
          <div className="header-card-img-box">
            <img src={analysis} alt="analysis" />
          </div>
        </div>
        <div className="header-card rounded-3 d-card-three users">
          <div className="header-card-text-box ms-4">
            <div className="header-card-text-total">Total Users</div>
            <div className="header-card-text-count">{totalUsers}</div>
          </div>
          <div className="header-card-img-box">
            <img src={people} alt="people" />
          </div>
        </div>
        <div className="header-card rounded-3 d-card-four files">
          <div className="header-card-text-box ms-4">
            <div className="header-card-text-total">Total Files</div>
            <div className="header-card-text-count">{totalFiles}</div>
          </div>
          <div className="header-card-img-box">
            <img src={folder} alt="folder" />
          </div>
        </div>
      </div>

      <DashboardCampaignReport data={projectData} />
      {/* <div className="dashboard-body">
        <CCard>
          <CCardBody>
            <CRow>
              <CChart
                type="line"
                data={{
                  labels: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ],
                  datasets: [
                    {
                      label: "File Filter Report",
                      backgroundColor: "rgba(220, 220, 220, 0.2)",
                      borderColor: "rgba(220, 220, 220, 1)",
                      pointBackgroundColor: "rgba(220, 220, 220, 1)",
                      pointBorderColor: "#fff",
                      data: graphArray,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        color: getStyle("--cui-body-color"),
                      },
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        color: getStyle("--cui-border-color-translucent"),
                      },
                      ticks: {
                        color: getStyle("--cui-body-color"),
                      },
                    },
                    y: {
                      grid: {
                        color: getStyle("--cui-border-color-translucent"),
                      },
                      ticks: {
                        color: getStyle("--cui-body-color"),
                      },
                    },
                  },
                }}
              />
            </CRow>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardBody>
            <CRow>
              <div className="recently-open-wrapper" style={{ height: "40vh", overflow: "auto" }}>
                <div className="recently-open-title mb-2">Recently opened files</div>
                {recentFiles.map((item) => (
                  // eslint-disable-next-line react/jsx-key
                  <div className="recently-open-card-wrapper">
                    <div className="recently-open-card">
                      <ExcelOutlineSvg />
                      <div>
                        <span className="mb-1 title">
                          {item.name ? item.name : "Campanign Name"}
                        </span>
                        <span className="name">{item.Project?.name}</span>
                      </div>
                    </div>
                    <CButton type="button" onClick={() => handleFileOpen(item)} color="primary">
                      {" "}
                      Open
                    </CButton>
                  </div>
                ))}
              </div>
            </CRow>
          </CCardBody>
        </CCard>
      </div> */}
    </>
  );
};

export default Dashboard;
