import axios from "axios";
import {API_URL} from "../enviroment/enviroment";



const updateProfile = async (input) => {
  const authToken = localStorage.getItem("auth");
  return axios
    .patch(`${API_URL}/users/profile`, input, {
      headers: {
        "Content-Type": "application/json",
        "Authorization":`${JSON.parse(authToken)}`
      },
    })
    .then((result) => {
      return result.data;
    });
};



const getProfile = async (id) => {
  const authToken = localStorage.getItem("auth");
  
  return axios
    .get(`${API_URL}/users/profile`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization":`${JSON.parse(authToken)}`
      },
    })
    .then((result) => {
      return result.data;
    });
};

export { getProfile, updateProfile };
