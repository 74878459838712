import {
  CButton,
  CCol,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { createFiles } from "src/services/file.services";
import { read, utils } from "xlsx";

const FileUploadModal = (props) => {
  const { visibleFileUploadModal, setVisibleFileUploadModal, projectId } = props;
  const [fileName, setFileName] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [fileSize, setFileSize] = React.useState(0);

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(createFiles, {
    onSuccess: () => {
      toast.dismiss();
      toast.success("File uploaded successfully");
      setVisibleFileUploadModal(false);
      queryClient.invalidateQueries(["files", projectId]);
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
  });

  useEffect(() => {
    let id;
    if (isLoading) id = toast.loading("Uploading...");
    return () => toast.dismiss(id);
  }, [isLoading]);

  const handleFile = (e) => {
    const file = e.target.files[0];
    const name = file.name.replace(/\.[^/.]+$/, "");
    setFileName(name);
    setFileSize(file.size);
    setFile(file);
  };

  useEffect(() => {
    setFileName("");
    setFile(null);
  }, [visibleFileUploadModal]);

  const readData = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = (e) => resolve(e.target?.result);

      fileReader.onerror = (e) => {
        reject(new Error("An error occurred while reading the file."));
      };

      fileReader.readAsBinaryString(file);
    });
  };

  const processFile = async (data) => {
    try {
      const workbook = read(data, { type: "binary" });

      let rows = [];
      let columns = [];

      for (const sheetName of workbook.SheetNames) {
        const XL_row_object = utils.sheet_to_json(workbook.Sheets[sheetName]);

        // Add unique ID to each row using the map() function
        const rowsWithUniqueId = XL_row_object.map((row, index) => ({
          ...row,
          uniqueId: index, // Assuming the row index can be used as a unique ID
        }));

        rows = rowsWithUniqueId;

        const workbookHeaders = read(data, { sheetRows: 1, type: "binary" });
        const columnsArray = utils.sheet_to_json(workbookHeaders.Sheets[sheetName], {
          header: 1,
        })[0];
        columns = columnsArray;
      }

      return { rows, columns };
    } catch (error) {
      throw new Error("An error occurred while processing the file.");
    }
  };

  const handleSubmit = async () => {
    if (!file) {
      toast.error("Please select a excel file to upload");
      return;
    }
    if (!fileName) {
      toast.error("Please enter a file name");
      return;
    }
    try {
      const data = await readData(file);
      const { rows, columns } = await processFile(data);
      mutate({
        allData: { selectedColumn: columns, data: rows },
        filteredData: { selectedColumn: [], data: rows },
        missingData: { selectedColumn: [], data: [] },
        validateDataRangeData: { selectedColumn: [], data: [] },
        identifyOutliersData: { selectedColumn: [], data: [] },
        crossTabulationData: { selectedColumn: [], data: [] },
        assessResponsePattersData: { selectedColumn: [], data: [] },
        validateOpenEndedResponseData: { selectedColumn: [], data: [] },
        duplicateRespondentData: { selectedColumn: [], data: [] },
        ipAddressLocationCheckData: { selectedColumn: [], data: [] },
        ipAddressDuplicateRespondentsData: { selectedColumn: [], data: [] },
        multiCodeLogicData: { selectedColumn: [], data: [] },
        allDataCount: rows.length,
        filteredDataCount: rows.length,
        missingDataCount: 0,
        validateDataRangeDataCount: 0,
        identifyOutliersDataCount: 0,
        crossTabulationDataCount: 0,
        assessResponsePattersDataCount: 0,
        validateOpenEndedResponseDataCount: 0,
        duplicateRespondentDataCount: 0,
        ipAddressLocationCheckDataCount: 0,
        ipAddressDuplicateRespondentsDataCount: 0,
        multiCodeLogicDataCount: 0,
        fileSize: fileSize,
        name: fileName,
        Project: projectId,
      });
    } catch (error) {
      toast.error("File is corrupted or wrong excel file.");
    }
  };
  return (
    <>
      <CModal
        backdrop="static"
        size="lg"
        alignment="center"
        visible={visibleFileUploadModal}
        onClose={() => setVisibleFileUploadModal()}>
        <CModalHeader className="border-0 px-4">
          <CModalTitle>File Upload</CModalTitle>
        </CModalHeader>
        <CModalBody className="px-4 py-0">
          <CRow className="align-items-center mb-3">
            <CFormLabel htmlFor="fileUpload" className="col-sm-3 col-form-label">
              Upload excel file
            </CFormLabel>
            <CCol sm={9}>
              <CFormInput
                type="file"
                id="fileUpload"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv, application/vnd.ms-excel"
                onChange={handleFile}
              />
            </CCol>
          </CRow>

          <CRow className="align-items-center mb-3">
            <CFormLabel htmlFor="fileName" className="col-sm-3 col-form-label">
              File Name
            </CFormLabel>
            <CCol sm={9}>
              <CFormInput
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                type="text"
                id="fileName"
              />
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter className="border-0 px-4 flex-end">
          <CButton type="button" onClick={handleSubmit} color="primary">
            Submit
          </CButton>
          <CButton
            className="text-white"
            type="button"
            color="secondary"
            onClick={() => setVisibleFileUploadModal()}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default FileUploadModal;
