import ApiClient from "./interceptor";

const getDashboardReport = async () => {
  return ApiClient.get("/reports/dashboard");
};

const getProjectReport = async (id, params) => {
  return ApiClient.get("/reports/project/" + id, { params });
};

const getFileReport = async (id) => {
  return ApiClient.get("/reports/file/" + id);
};

export { getDashboardReport, getProjectReport, getFileReport };
