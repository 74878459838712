import { FilterTypes } from "../commontypes";
import { removeHiddenRows } from "./commonHelper";

const handleMultiCodeLogic = (
  columns,
  rows,
  selectedColumns,
  hiddenData,
  setHiddenData,
  filterTypes,
) => {
  let obj = {};
  let newRows = [];
  const _selectedColumns = selectedColumns[filterTypes];
  const _hiddenData = { ...hiddenData };
  _hiddenData[FilterTypes.MULTI_CODE_LOGIC] = _hiddenData[FilterTypes.MULTI_CODE_LOGIC] || [];

  newRows = rows.filter((row, index) => {
    //separrate the column by comma
    selectedColumns[filterTypes].forEach((_column) => {
      const column = _column.column;
      const length = Number(_column.length);
      const value = String(row[columns[column]]);

      if (!value) return false;
      const columnValues = value.split(",");

      if (columnValues.length > length) {
        _hiddenData[FilterTypes.MULTI_CODE_LOGIC].push(row);
        return false;
      }
      return true;
    });
  });
  setHiddenData(_hiddenData);
  newRows = removeHiddenRows(rows, _hiddenData);
  return { _hiddenData, result: newRows };
};

export { handleMultiCodeLogic };
