import { FilterTypes } from "../commontypes";
import { removeHiddenRows } from "./commonHelper";

const handleFilterMissingData = (columns, rows) => {
  const columnLength = columns.length;
  const newRows = rows.filter((row) => {
    if (Object.keys(row).length !== columnLength) {
      return false;
    } else {
      return true;
    }
  });
  return newRows;
};

const handleFilterSelectedColumnsMissingData = (
  columns,
  rows,
  selectedColumns,
  hiddenData,
  setHiddenData,
  filterTypes,
) => {
  const _hiddenData = { ...hiddenData };
  _hiddenData[FilterTypes.CHECK_FOR_MISSING_DATA] =
    _hiddenData[FilterTypes.CHECK_FOR_MISSING_DATA] || [];
  const _selectedColumns = selectedColumns[filterTypes];
  let result = rows.filter((row) => {
    for (let i = 0; i < _selectedColumns.length; i++) {
      if (row[columns[_selectedColumns[i]]] === undefined) {
        _hiddenData[FilterTypes.CHECK_FOR_MISSING_DATA].push(row);
        return false;
      }
    }
    return true;
  });
  setHiddenData(_hiddenData);
  result = removeHiddenRows(rows, _hiddenData);
  return { _hiddenData, result };
};
const handleShowMissingData = (columns, rows) => {
  const columnLength = columns.length;
  const newRows = rows.filter((row) => {
    if (Object.keys(row).length !== columnLength) {
      return true;
    } else {
      return false;
    }
  });
  return newRows;
};

export { handleFilterMissingData, handleShowMissingData, handleFilterSelectedColumnsMissingData };
