import React, { useEffect, useState } from "react";
import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { CChartBar, CChartDoughnut, CChartLine } from "@coreui/react-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload } from "@coreui/icons";
import { getProjectReport } from "src/services/report.services";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import GoBackSvg from "src/assets/svg/GoBackSvg";
// import ProjectReportIcon from "src/assets/images/project_report-icon.png";

const DashboardCampaignReport = (props) => {
  const [id, setId] = useState(null);
  const filterBy = ["Day", "Month", "Year"];
  const [selectedFilter, setSelectedFilter] = useState("Month");
  const [reportParams, setReportParams] = useState({
    from: "2023-01",
    to: "2023-12",
    type: selectedFilter,
  });
  const [dayFromDate, setDayFromDate] = useState();
  const [dayToDate, setDayToDate] = useState();
  const [monthFromDate, setMonthFromDate] = useState("01");
  const [monthToDate, setMonthToDate] = useState("12");
  const [monthYear, setMonthYear] = useState(2023);
  const [yearFrom, setYearFrom] = useState(2023);
  const [yearTo, setYearTo] = useState(2023);
  const [campaignDetails, setCampaignDetails] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [uploadedFilesArray, setUploadedFilesArray] = useState([]);
  const [graphHeadArray, setGraphHeadArray] = useState([]);
  const [graphAllDataCount, setGraphAllDataCount] = useState([]);
  const [graphAssessResponsePattersDataCount, setGraphAssessResponsePattersDataCount] = useState(
    [],
  );
  const [graphCrossTabulationDataCount, setGraphCrossTabulationDataCount] = useState([]);
  const [graphDuplicateRespondentDataCount, setGraphDuplicateRespondentDataCount] = useState([]);
  const [graphFilteredDataCount, setGraphFilteredDataCount] = useState([]);
  const [graphIdentifyOutliersDataCount, setGraphIdentifyOutliersDataCount] = useState([]);
  const [
    graphIpAddressDuplicateRespondentsDataCount,
    setGraphIpAddressDuplicateRespondentsDataCount,
  ] = useState([]);
  const [graphIpAddressLocationCheckDataCount, setGraphIpAddressLocationCheckDataCount] = useState(
    [],
  );
  const [graphMissingDataCount, setGraphMissingDataCount] = useState([]);
  const [graphValidateDataRangeDataCount, setGraphValidateDataRangeDataCount] = useState([]);
  const [graphValidateOpenEndedResponseDataCount, setGraphValidateOpenEndedResponseDataCount] =
    useState([]);

  const [filterDetailsName, setFilterDetailsName] = useState([]);
  const [filterDetailsPercentage, setFilterDetailsPercentage] = useState([]);
  const [filterDetailsColor, setFilterDetailsColor] = useState([]);

  const navigate = useNavigate();

  const monthNameObj = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  const yearOption = [
    { label: 2023, value: 2023 },
    { label: 2024, value: 2024 },
  ];
  const monthOption = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  // useEffect(() => {
  //   if (props.data.length > 0) {
  //     if (props.data[1]?.value) {
  //       setId(props.data[1].value);
  //     }
  //   }
  // }, [props.data]);

  const {
    data: projectReport,
    isLoading: isLoading,
    onError: isUserDataError,
  } = useQuery(["reports", id, reportParams], () => getProjectReport(id, reportParams), {
    // staleTime: 1000 * 60 * 5,
    enabled: !!id && !!reportParams,
    refetchOnWindowFocus: false,
  });

  const reportGraphDataLabel = {
    allDataCount: {
      label: "All Data",
      color: "success",
    },
    filteredDataCount: {
      label: "Filtered Data",
      color: "primary",
    },
    missingDataCount: {
      label: "Missing Data",
      color: "secondary",
    },
    validateDataRangeDataCount: {
      label: "Data Range Validation",
      color: "warning",
    },
    identifyOutliersDataCount: {
      label: "Identify Outliers",
      color: "info",
    },
    crossTabulationDataCount: {
      label: "cross Tabulation And Consistency",
      color: "danger",
    },
    assessResponsePattersDataCount: {
      label: "Flat Liners",
      color: "light",
    },
    validateOpenEndedResponseDataCount: {
      label: "Open Ended Response",
      color: "dark",
    },
    duplicateRespondentDataCount: {
      label: "Duplicate Respondents(BOTS)",
      color: "success",
    },
    ipAddressDuplicateRespondentsDataCount: {
      label: "Duplicate Respondents(IP Address)",
      color: "primary",
    },
    ipAddressLocationCheckDataCount: {
      label: "Location Check",
      color: "secondary",
    },
  };

  useEffect(() => {
    if (!id) {
      resetGraph();
    }
  }, [id]);

  useEffect(() => {
    if (props.data && props.data.length > 1) {
      let firstId = props.data[1]["value"];
      setId(firstId);
    }
  }, [props.data]);

  const resetGraph = () => {
    setGraphHeadArray([]);
    setUploadedFilesArray([]);
    setGraphAllDataCount([]);
    setGraphAssessResponsePattersDataCount(0);
    setGraphCrossTabulationDataCount(0);
    setGraphDuplicateRespondentDataCount(0);
    setGraphFilteredDataCount(0);
    setGraphIdentifyOutliersDataCount(0);
    setGraphIpAddressDuplicateRespondentsDataCount(0);
    setGraphIpAddressLocationCheckDataCount(0);
    setGraphMissingDataCount(0);
    setGraphValidateDataRangeDataCount(0);
    setGraphValidateOpenEndedResponseDataCount(0);
    setCampaignDetails([]);
    setFilterDetailsName([]);
    setFilterDetailsPercentage([]);
    setFilterDetailsColor([]);
  };

  useEffect(() => {
    if (projectReport && projectReport.data.length > 0) {
      const projectReportData = projectReport.data[0];
      const projectReportObj = projectReport.report;

      const totalDataCount = projectReportData.allDataCount;
      let tableData = [];
      let nameArray = [];
      let percentageArray = [];
      let colorArray = [];
      for (const key in projectReportData) {
        if (key != "_id") {
          const obj = {
            name: reportGraphDataLabel[key]["label"],
            measurement: projectReportData[key],
            percentage: ((100 * projectReportData[key]) / totalDataCount).toFixed(2),
            color: reportGraphDataLabel[key]["color"],
          };
          tableData.push(obj);
          if (key != "allDataCount") {
            nameArray.push(obj.name);
            percentageArray.push(obj.percentage);
            colorArray.push(obj.color);
          }
        }
      }

      setCampaignDetails(tableData);
      setFilterDetailsName(nameArray);
      setFilterDetailsPercentage(percentageArray);
      setFilterDetailsColor(colorArray);

      let graphHead = [];
      let files = [];
      let allDataCount = [];
      let assessResponsePattersDataCount = [];
      let crossTabulationDataCount = [];
      let duplicateRespondentDataCount = [];
      let filteredDataCount = [];
      let identifyOutliersDataCount = [];
      let ipAddressDuplicateRespondentsDataCount = [];
      let ipAddressLocationCheckDataCount = [];
      let missingDataCount = [];
      let validateDataRangeDataCount = [];
      let validateOpenEndedResponseDataCount = [];

      projectReportObj.forEach((element) => {
        if (selectedFilter == "Month") {
          graphHead.push(monthNameObj[Number(element.Date)]);
        } else {
          graphHead.push(element.Date);
        }

        files.push(Number(element.files));
        allDataCount.push(element.allDataCount);
        assessResponsePattersDataCount.push(element.assessResponsePattersDataCount);
        crossTabulationDataCount.push(element.crossTabulationDataCount);
        duplicateRespondentDataCount.push(element.duplicateRespondentDataCount);
        filteredDataCount.push(element.filteredDataCount);
        identifyOutliersDataCount.push(element.identifyOutliersDataCount);
        ipAddressDuplicateRespondentsDataCount.push(element.ipAddressDuplicateRespondentsDataCount);
        ipAddressLocationCheckDataCount.push(element.ipAddressLocationCheckDataCount);
        missingDataCount.push(element.missingDataCount);
        validateDataRangeDataCount.push(element.validateDataRangeDataCount);
        validateOpenEndedResponseDataCount.push(element.validateOpenEndedResponseDataCount);
      });

      setGraphHeadArray(graphHead);
      setUploadedFilesArray(files);
      setGraphAllDataCount(allDataCount);
      setGraphAssessResponsePattersDataCount(assessResponsePattersDataCount);
      setGraphCrossTabulationDataCount(crossTabulationDataCount);
      setGraphDuplicateRespondentDataCount(duplicateRespondentDataCount);
      setGraphFilteredDataCount(filteredDataCount);
      setGraphIdentifyOutliersDataCount(identifyOutliersDataCount);
      setGraphIpAddressDuplicateRespondentsDataCount(ipAddressDuplicateRespondentsDataCount);
      setGraphIpAddressLocationCheckDataCount(ipAddressLocationCheckDataCount);
      setGraphMissingDataCount(missingDataCount);
      setGraphValidateDataRangeDataCount(validateDataRangeDataCount);
      setGraphValidateOpenEndedResponseDataCount(validateOpenEndedResponseDataCount);
    }
  }, [projectReport]);

  const handleDayReportSubmit = (e) => {
    e.preventDefault();
    if (selectedFilter === "Day") {
      const params = { from: dayFromDate, to: dayToDate, type: selectedFilter };
      setReportParams(params);
    } else if (selectedFilter === "Month") {
      const fromDate = monthYear + "-" + monthFromDate;
      const toDate = monthYear + "-" + monthToDate;
      const params = { from: fromDate, to: toDate, type: selectedFilter };
      setReportParams(params);
    } else {
      const fromDate = yearFrom;
      const toDate = yearTo;
      const params = { from: fromDate, to: toDate, type: selectedFilter };
      setReportParams(params);
    }
  };

  const onError = (err) => {
    console.error(err);
  };

  const handleDownload = () => {
    const fileName = "campaign-report";
    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(campaignDetails);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a binary XLSX file and save it
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="rounded-3 border-0 mb-4 p-3">
          <CCardBody>
            <CRow className="pb-3">
              <CCol className="flex-start mb-3" sm={12}>
                {/* <div
                    className="flex-center  cursor-pointer margin-right-2"
                    onClick={() => navigate(-1)}>
                    <GoBackSvg />
                    <div>Back</div>
                  </div> */}
                <h4 id="traffic" className="card-title mb-0 margin-right-2">
                  {/* <img
                      style={{ width: "1.5rem", margin: "0 0.5rem" }}
                      src={ProjectReportIcon}
                      alt="project report"
                    /> */}
                  Project Report
                </h4>
                <div className="small text-medium-emphasis">
                  {reportParams?.from} to {reportParams?.to}
                </div>
              </CCol>
            </CRow>
            <CRow className="dashboard-filter bg-gray-50 rounded-3 align-items-center px-2 py-3 mb-4">
              <CCol sm={10}>
                {selectedFilter === "Day" ? (
                  <CForm onSubmit={(e) => handleDayReportSubmit(e)}>
                    <CRow className="report-filter align-items-center">
                      <CCol>
                        <div className="d-flex align-items-center gap-3">
                          <CFormLabel htmlFor="userType" className="col-form-label">
                            Project:
                          </CFormLabel>
                          <CFormSelect
                            value={id}
                            options={props.data}
                            onChange={(e) => {
                              setId(e.target.value);
                            }}
                          />
                        </div>
                      </CCol>
                      <CCol>
                        <div className="report-filter d-flex align-items-center gap-3">
                          <CFormLabel htmlFor="dayFromDate" className="col-form-label">
                            From:
                          </CFormLabel>
                          <CFormInput
                            type="date"
                            id="dayFromDate"
                            value={dayFromDate}
                            onChange={(e) => {
                              setDayFromDate(e.target.value);
                            }}
                          />
                        </div>
                      </CCol>
                      <CCol>
                        <div className="d-flex align-items-center gap-3">
                          <CFormLabel htmlFor="dayToDate" className="col-form-label">
                            to:
                          </CFormLabel>
                          <CFormInput
                            type="date"
                            id="dayToDate"
                            value={dayToDate}
                            onChange={(e) => {
                              setDayToDate(e.target.value);
                            }}
                          />
                        </div>
                      </CCol>
                      <CCol>
                        <CButton type="submit" color="primary">
                          Submit
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                ) : (
                  ""
                )}

                {selectedFilter === "Month" ? (
                  <CForm onSubmit={(e) => handleDayReportSubmit(e)}>
                    <CRow className="report-filter align-items-center">
                      <CCol>
                        <div className="d-flex align-items-center gap-3">
                          <CFormLabel htmlFor="userType" className="col-form-label">
                            Project:
                          </CFormLabel>
                          <CFormSelect
                            value={id}
                            options={props.data}
                            onChange={(e) => {
                              setId(e.target.value);
                            }}
                          />
                        </div>
                      </CCol>
                      <CCol>
                        <div className="d-flex align-items-center gap-3">
                          <CFormLabel htmlFor="userType" className="col-form-label">
                            Year:
                          </CFormLabel>
                          <CFormSelect
                            value={monthYear}
                            options={yearOption}
                            onChange={(e) => {
                              setMonthYear(e.target.value);
                            }}
                          />
                        </div>
                      </CCol>
                      <CCol>
                        <div className="d-flex align-items-center gap-3">
                          <CFormLabel htmlFor="userType" className="col-form-label">
                            From:
                          </CFormLabel>
                          <CFormSelect
                            value={monthFromDate}
                            options={monthOption}
                            onChange={(e) => {
                              setMonthFromDate(e.target.value);
                            }}
                          />
                        </div>
                      </CCol>
                      <CCol>
                        <div className="d-flex align-items-center gap-3">
                          <CFormLabel htmlFor="userType" className="col-form-label">
                            to:
                          </CFormLabel>
                          <CFormSelect
                            value={monthToDate}
                            options={monthOption}
                            onChange={(e) => {
                              setMonthToDate(e.target.value);
                            }}
                          />
                        </div>
                      </CCol>
                      <CCol>
                        <CButton type="submit" color="primary">
                          Submit
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                ) : (
                  ""
                )}

                {selectedFilter === "Year" ? (
                  <CForm onSubmit={(e) => handleDayReportSubmit(e)}>
                    <CRow className="report-filter align-items-center">
                      <CCol>
                        <div className="d-flex align-items-center gap-3">
                          <CFormLabel htmlFor="userType" className="col-form-label">
                            Project:
                          </CFormLabel>
                          <CFormSelect
                            value={id}
                            options={props.data}
                            onChange={(e) => {
                              setId(e.target.value);
                            }}
                          />
                        </div>
                      </CCol>
                      <CCol>
                        <div className="d-flex align-items-center gap-3">
                          <CFormLabel htmlFor="userType" className="col-form-label">
                            From:
                          </CFormLabel>
                          <CFormSelect
                            value={yearFrom}
                            options={yearOption}
                            onChange={(e) => {
                              setYearFrom(e.target.value);
                            }}
                          />
                        </div>
                      </CCol>
                      <CCol>
                        <div className="d-flex align-items-center gap-3">
                          <CFormLabel htmlFor="userType" className="col-form-label">
                            to:
                          </CFormLabel>
                          <CFormSelect
                            value={yearTo}
                            options={yearOption}
                            onChange={(e) => {
                              setYearTo(e.target.value);
                            }}
                          />
                        </div>
                      </CCol>
                      <CCol>
                        <CButton type="submit" color="primary">
                          Submit
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                ) : (
                  ""
                )}
              </CCol>
              <CCol sm={2} className="d-none d-md-block flex-start">
                <CButtonGroup className="filter-tab float-end me-3">
                  {filterBy.map((value) => (
                    <CButton
                      onClick={() => {
                        setSelectedFilter(value);
                      }}
                      color="outline-secondary"
                      key={value}
                      className="mx-0"
                      active={value === selectedFilter}>
                      {value}
                    </CButton>
                  ))}
                </CButtonGroup>
              </CCol>
            </CRow>
            <CChartLine
              style={{ height: "300px", marginTop: "16px" }}
              data={{
                labels: graphHeadArray,
                datasets: [
                  {
                    label: "Filtered Data",
                    backgroundColor: hexToRgba(getStyle("--cui-primary"), 10),
                    borderColor: getStyle("--cui-primary"),
                    pointHoverBackgroundColor: getStyle("--cui-primary"),
                    borderWidth: 2,
                    data: graphFilteredDataCount,
                    fill: true,
                  },
                  {
                    label: "Missing Data",
                    backgroundColor: "transparent",
                    borderColor: getStyle("--cui-secondary"),
                    pointHoverBackgroundColor: getStyle("--cui-secondary"),
                    borderWidth: 2,
                    data: graphMissingDataCount,
                  },
                  {
                    label: "Data Range",
                    backgroundColor: "transparent",
                    borderColor: getStyle("--cui-warning"),
                    pointHoverBackgroundColor: getStyle("--cui-warning"),
                    borderWidth: 1,
                    borderDash: [8, 5],
                    data: graphValidateDataRangeDataCount,
                  },
                  {
                    label: "Identify Outliers",
                    backgroundColor: "transparent",
                    borderColor: getStyle("--cui-info"),
                    pointHoverBackgroundColor: getStyle("--cui-info"),
                    borderWidth: 1,
                    borderDash: [8, 5],
                    data: graphIdentifyOutliersDataCount,
                  },
                  {
                    label: "Cross Tabulation and Consistency",
                    backgroundColor: "transparent",
                    borderColor: getStyle("--cui-danger"),
                    pointHoverBackgroundColor: getStyle("--cui-danger"),
                    borderWidth: 2,
                    data: graphCrossTabulationDataCount,
                  },
                  {
                    label: "AssessResponse Patterns",
                    backgroundColor: hexToRgba(getStyle("--cui-light"), 10),
                    borderColor: getStyle("--cui-light"),
                    pointHoverBackgroundColor: getStyle("--cui-light"),
                    borderWidth: 2,
                    data: graphAssessResponsePattersDataCount,
                    fill: true,
                  },
                  {
                    label: "Open Ended Response",
                    backgroundColor: "transparent",
                    borderColor: getStyle("--cui-dark"),
                    pointHoverBackgroundColor: getStyle("--cui-dark"),
                    borderWidth: 2,
                    data: graphValidateOpenEndedResponseDataCount,
                  },
                  {
                    label: "Duplicate Respondent",
                    backgroundColor: "transparent",
                    borderColor: getStyle("--cui-success"),
                    pointHoverBackgroundColor: getStyle("--cui-success"),
                    borderWidth: 2,
                    data: graphDuplicateRespondentDataCount,
                  },
                  {
                    label: "Duplicate Respondents(ipAddress)",
                    backgroundColor: "transparent",
                    borderColor: getStyle("--cui-primary"),
                    pointHoverBackgroundColor: getStyle("--cui-primary"),
                    borderWidth: 1,
                    borderDash: [8, 5],
                    data: graphIpAddressDuplicateRespondentsDataCount,
                  },
                  {
                    label: "Location Check",
                    backgroundColor: hexToRgba(getStyle("--cui-secondary"), 10),
                    borderColor: getStyle("--cui-secondary"),
                    pointHoverBackgroundColor: getStyle("--cui-secondary"),
                    borderWidth: 2,
                    data: graphIpAddressLocationCheckDataCount,
                    fill: true,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    grid: {
                      drawOnChartArea: false,
                    },
                  },
                  y: {
                    ticks: {
                      beginAtZero: true,
                      maxTicksLimit: 5,
                      stepSize: Math.ceil(250 / 5),
                      max: 250,
                    },
                  },
                },
                elements: {
                  line: {
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3,
                  },
                },
              }}
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs={12}>
        <CCard className="project-report-card rounded-3 border-0 mb-4 p-3">
          <CCardBody className="bg-transparent border-0">
            <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-start g-3">
              {campaignDetails.map((item, index) => (
                <CCol md="3" key={index} className="card-col">
                  <div className="bg-card p-3 rounded-3">
                    <div className="c-subtitle text-medium-emphasis">{item.name}</div>
                    <strong className="c-title">
                      {item.value} {item.percentage}%
                    </strong>
                    <CProgress
                      thin
                      className="mt-2"
                      height={10}
                      color={item.color}
                      value={item.percentage}
                    />
                  </div>
                </CCol>
              ))}
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      {/* <WidgetsBrand withCharts /> */}

      <CCol xs={7}>
        <CCard className="rounded-3 border-0 mb-4">
          <CCardHeader className="p-3 bg-transparent border-0">
            {/* <h5 className="report-title pt-3">Uploaded Files</h5> */}
            <h4 id="traffic" className="card-title mb-0 margin-right-2">
              Uploaded Files
            </h4>
          </CCardHeader>
          <CCardBody>
            <CChartBar
              data={{
                labels: graphHeadArray,
                datasets: [
                  {
                    label: "Total Files",
                    backgroundColor: "#f87979",
                    data: uploadedFilesArray,
                  },
                ],
              }}
              labels="months"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs={5}>
        <CCard className="dc-chart rounded-3 border-0 mb-4">
          <CCardHeader className="p-3 bg-transparent border-0">
            <h4 id="traffic" className="card-title mb-0 margin-right-2">
              Filter Details (Percentage)
            </h4>
          </CCardHeader>
          <CCardBody>
            <CChartDoughnut
              data={{
                labels: filterDetailsName,
                datasets: [
                  {
                    // backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
                    backgroundColor: [
                      "#2ECC71",
                      "#FF5733",
                      "#3498DB",
                      "#E74C3C",
                      "#9B59B6",
                      "#F39C12",
                      "#1ABC9C",
                      "#D35400",
                      "#27AE60",
                      "#8E44AD",
                      "#C0392B",
                    ],
                    data: filterDetailsPercentage,
                  },
                ],
              }}
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default DashboardCampaignReport;
