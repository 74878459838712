import { FilterTypes } from "../commontypes";

const handleFilterSelectedColumnsDuplicateRespondentsData = (
  columns,
  rows,
  selectedColumns,
  hiddenData,
  setHiddenData,
  filterTypes,
) => {
  let obj = {};
  let newRows = [];
  const _selectedColumns = selectedColumns[filterTypes];
  const _hiddenData = { ...hiddenData };

  const selectedColumnsNames = [];
  _selectedColumns.forEach((item) => {
    selectedColumnsNames.push(columns[item]);
  });

  const seenValues = new Set(); // Keep track of seen values in the selected columns
  const matchedKeys = new Set(); // Keep track of matched keys

  newRows = rows.filter((row) => {
    const selectedValues = selectedColumnsNames.map((colName) => row[colName]);
    const key = selectedValues.join("|")?.toString()?.toLowerCase();

    for (let seenValue of seenValues) {
      const seenValuesArray = seenValue.split("|");
      const matchingValues = selectedValues.filter((value) =>
        seenValuesArray.includes(value?.toString()?.toLowerCase()),
      );

      if (matchingValues.length / selectedValues.length >= 0.8) {
        _hiddenData[FilterTypes.DUPLICATE_RESPONDENTS].push(row);
        matchedKeys.add(key);
        return false;
      }
    }

    seenValues.add(key);
    return true;
  });

  //find matched keys and add them to hidden data and remove them from newRows
  for (let key of matchedKeys) {
    const matchingRows = newRows.filter((row) => {
      const selectedValues = selectedColumnsNames.map((colName) => row[colName]);
      const rowKey = selectedValues.join("|")?.toString()?.toLowerCase();
      return rowKey === key;
    });

    _hiddenData[FilterTypes.DUPLICATE_RESPONDENTS] = [
      ..._hiddenData[FilterTypes.DUPLICATE_RESPONDENTS],
      ...matchingRows,
    ];

    newRows = newRows.filter((row) => {
      const selectedValues = selectedColumnsNames.map((colName) => row[colName]);
      const rowKey = selectedValues.join("|")?.toString()?.toLowerCase();
      return rowKey !== key;
    });
  }

  //removed mat

  setHiddenData(_hiddenData);

  return { _hiddenData, result: newRows };
};

export { handleFilterSelectedColumnsDuplicateRespondentsData };
