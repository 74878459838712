import { cilArrowLeft, cilPlus, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CCard, CCardBody, CCol, CRow } from "@coreui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import ExcelSolidSvg from "src/assets/svg/ExcelSolidSvg";
import nofiles from "src/assets/images/no-files.png";
import GoBackSvg from "src/assets/svg/GoBackSvg";
import CustomPagination from "src/components/CustomPagination";
import DeleteModal from "src/components/DeleteModal/DeleteModal";
import FileUploadModal from "src/components/Modals/FileUploadModal";
import { deleteFiles, getAllFilesByProjectId } from "src/services/file.services";
import ReportSvg from "src/assets/svg/ReportSvg";

const Campaign = () => {
  const userInfo = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
  const { id, projectName } = useParams();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [visibleFileUploadModal, setVisibleFileUploadModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery(
    ["files", id, page, limit],
    () => getAllFilesByProjectId({ id, params: { page, limit } }),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      enabled: !!id,
      retry: false,
    },
  );

  const getBaseUrl = () => {
    const { origin, pathname } = window.location;
    return origin + pathname;
  };
  const baseUrl = getBaseUrl();
  const { mutate: deleteFilesMutation, isLoading: deleteFilesIsLoading } = useMutation(
    deleteFiles,
    {
      onSuccess: () => {
        toast.dismiss();
        queryClient.invalidateQueries("files");
        toast.success("File deleted successfully");
      },
      onError: () => {
        toast.dismiss();
        toast.error("Error deleting file");
      },
    },
  );
  useEffect(() => {
    let id;
    if (isLoading) id = toast.loading("Loading...");
    if (isError) toast.dismiss(id);

    if (data) toast.dismiss(id);
    if (deleteFilesIsLoading) id = toast.loading("Deleting file...");
    return () => toast.dismiss(id);
  }, [isLoading, isError, data, deleteFilesIsLoading]);

  const formatFileSize = (size) => {
    if (size < 1024) {
      return size + " Bytes";
    } else if (size >= 1024 && size < 1048576) {
      return (size / 1024).toFixed(1) + " KB";
    } else if (size >= 1048576) {
      return (size / 1048576).toFixed(1) + " MB";
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleFileUpload = () => {
    setVisibleFileUploadModal(true);
  };

  const handleFileOpen = (id) => {
    navigate("/apply-filters/" + id);
  };

  const handleFileReport = (id) => {
    navigate("/report/" + id);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setVisibleDeleteModal(true);
  };

  const submitDelete = () => {
    deleteFilesMutation(deleteId);
    setVisibleDeleteModal(false);
  };

  return (
    <div className="bg-white">
      <CCard className="border-0">
        <CCardBody className="campaign-card">
          <CRow>
            <CCol sm={12}>
              <div className="campaign-card-back">
                <CButton
                  color="light"
                  variant="ghost"
                  className="text-uppercase px-0"
                  onClick={() => navigate(-1)}>
                  <GoBackSvg /> Back
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard className="border-0 border-bottom rounded-0  bg-gray-50 mb-2">
        <CCardBody className="campaign-card">
          <CRow>
            <CCol sm={2} className="d-none d-md-block">
              <h4 id="traffic" className="card-title mb-0">
                {projectName || "Untitled"}
              </h4>
            </CCol>
            <CCol sm={10} className="d-none d-md-block">
              {userInfo?.permission?.addFile || userInfo?.permission?.uploadFile ? (
                <div className="d-flex justify-content-end">
                  <CButton type="button" onClick={handleFileUpload} color="primary">
                    <CIcon icon={cilPlus} />
                    &nbsp; Upload File
                  </CButton>
                </div>
              ) : (
                ""
              )}
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <br />
      {data && data.data.length > 0 ? (
        data?.data?.map((item) => {
          return (
            <div key={item._id} className="p-3">
              <CCard className="project-list-card rounded-3 border mb-2">
                <CCardBody className="campaign-card">
                  <CRow>
                    <CCol sm={5}>
                      <div className="campaign-card-title">
                        <div className="mb-1 text-gray">Project Name</div>
                        <h4 id="traffic" className="card-title mb-0 ml-4">
                          {item.name || "Untitled"}
                        </h4>
                      </div>
                    </CCol>
                    <CCol sm={7} className="d-none d-md-block">
                      <div className="project-details-body d-flex justify-content-end align-items-center gap-5">
                        <div className="project-details d-flex justify-content-end align-items-center gap-5">
                          <div className="project-details-list">
                            <p className="mb-1 text-gray">File size:</p>
                            <p className="mb-1 font-bold">{formatFileSize(item.fileSize) || 0}</p>
                          </div>
                          <div className="project-details-list">
                            <p className="mb-1 text-gray">Data count:</p>
                            <p className="mb-1 font-bold">{item.allDataCount || 0}</p>
                          </div>
                        </div>
                        <div className="d-flex gap-2">
                          <CButton
                            type="button"
                            onClick={() => handleFileReport(item._id)}
                            color="light">
                            Report
                          </CButton>
                          <CButton
                            onClick={() => handleFileOpen(item._id)}
                            type="button"
                            color="primary">
                            {" "}
                            Open
                          </CButton>
                          {userInfo?.permission?.deleteFile ? (
                            <CButton
                              onClick={() => handleDelete(item._id)}
                              color="danger"
                              type="button"
                              className="delete-icon"
                              // onClick={() => {
                              //   setVisibleDeleteModal(true);
                              //   setData(item);
                              // }}
                            >
                              <CIcon icon={cilTrash} />
                            </CButton>
                          ) : (
                            <CButton
                              disabled={true}
                              color="danger"
                              type="button"
                              className="delete-icon"
                              // onClick={() => {
                              //   setVisibleDeleteModal(true);
                              //   setData(item);
                              // }}
                            >
                              <CIcon icon={cilTrash} />
                            </CButton>
                          )}
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </div>
          );
        })
      ) : (
        <div className="p-3">
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "50vh" }}>
            <div className="nofiles-share">
              <img src={nofiles} alt="nofiles" />
            </div>
            <h4>No data found!</h4>
          </div>
        </div>
      )}

      {data && data.data.length > 0 && (
        <div className="p-3">
          <CustomPagination
            currentPage={page || 1}
            totalPages={Math.ceil(data?.count / 5) || 1}
            onPageChange={handlePageChange}
          />
        </div>
      )}

      <FileUploadModal
        visibleFileUploadModal={visibleFileUploadModal}
        setVisibleFileUploadModal={setVisibleFileUploadModal}
        projectId={id}
      />

      <DeleteModal
        visibleDeleteModal={visibleDeleteModal}
        setVisibleDeleteModal={setVisibleDeleteModal}
        submitDelete={submitDelete}
      />
    </div>
  );
};

export default Campaign;
