import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormCheck,
  CFormSwitch,
} from "@coreui/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { getProfile, updateProfile } from "src/services/profile.services";
// import demoAvatar from "src/assets/images/avatars/avatar.png";
import toast from "react-hot-toast";

const schema = z
  .object({
    userType: z.string().nonempty("Please select user type"),
    firstName: z.string().min(1, "Please type first name"),
    lastName: z.string().min(1, "Please type last name"),
    email: z.string().min(1, "Please type email").email("This is not a valid email."),
    password: z.string().min(1, "Password is required"),
    confirmPassword: z.string().min(1, "Password confirmation is required"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "Passwords do not match",
  });

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const defaultErrorMessage = "Something went wrong";

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = (id) => {
    // setIsLoading(true)
    getProfile()
      .then(async (response) => {
        setUserData(response.data);
        const { userType, email, firstName, lastName } = response.data;
        setValue("userType", userType);
        setValue("email", email);
        setValue("firstName", firstName);
        setValue("lastName", lastName);
        setValue("password", "******");
        setValue("confirmPassword", "******");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userType: "",
      email: "",
      firstName: "",
      lastName: "",
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = (data) => {
    const _data = { ...data };
    if (!isPasswordChange) {
      delete _data.password;
      delete _data.confirmPassword;
    }

    toast
      .promise(
        updateProfile(_data),
        {
          loading: "Loading",
          success: "Profile Updated Succesfully",
          error: (err) => {
            return err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : defaultErrorMessage;
          },
        },
        {
          id: "login",
        },
      )
      .then(async (response) => {
        const updatedData = response.data;
        setUserData(response.data);
        const storageUserObj = {
          id: updatedData._id,
          createdAt: updatedData.createdAt,
          email: updatedData.email,
          firstName: updatedData.firstName,
          lastName: updatedData.lastName,
          permission: updatedData.permission,
          userType: updatedData.userType,
        };
        localStorage.setItem("user", JSON.stringify(storageUserObj));
      })
      .catch((error) => console.error(error));
  };

  const onError = (err) => {
    console.error(err);
  };

  const handlePasswordChange = (e) => {
    if (e.target.checked) {
      setValue("password", "");
      setValue("confirmPassword", "");
    } else {
      setValue("password", "******");
      setValue("confirmPassword", "******");
    }
    setIsPasswordChange(e.target.checked);
  };

  return (
    <CForm onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="row justify-content-center gutters-sm">
        <div className="col-md-6">
          <div className="user-managment-card card rounded-3 p-3 border-0 mb-3">
            <div className="card-body">
              <CRow className="mb-3">
                <CCol sm={12}>
                  <h4 id="traffic" className="card-title mb-4">
                    Profile
                  </h4>
                </CCol>
                <CCol sm={6}>
                  <CFormLabel htmlFor="firstName" className="col-sm-3 col-form-label">
                    First Name:
                  </CFormLabel>
                  <CFormInput type="text" id="firstName" {...register("firstName")} />
                  {errors.firstName?.message && (
                    <p className="error_text">{errors.firstName?.message}</p>
                  )}
                </CCol>
                <CCol sm={6}>
                  <CFormLabel htmlFor="lastName" className="col-sm-3 col-form-label">
                    Last Name:
                  </CFormLabel>
                  <CFormInput type="text" id="lastName" {...register("lastName")} />
                  {errors.lastName?.message && (
                    <p className="error_text">{errors.lastName?.message}</p>
                  )}
                </CCol>
                <CCol sm={12}>
                  <CFormLabel htmlFor="email" className="col-sm-3 col-form-label">
                    Email:
                  </CFormLabel>
                  <CFormInput disabled type="email" id="email" {...register("email")} />
                  {errors.email?.message && <p className="error_text">{errors.email?.message}</p>}
                </CCol>
              </CRow>
              <CRow className="py-4">
                <CCol sm={12}>
                  <CFormSwitch
                    size="xl"
                    onChange={(e) => handlePasswordChange(e)}
                    label="Change Password"
                    id="formSwitchCheckDefault"
                  />
                </CCol>
              </CRow>

              {isPasswordChange && (
                <>
                  <CRow className="mb-3">
                    <CCol sm={6}>
                      <CFormLabel htmlFor="password" className="col-sm-3 col-form-label">
                        Password:
                      </CFormLabel>
                      <CFormInput type="password" id="password" {...register("password")} />
                      {errors.password?.message && (
                        <p className="error_text">{errors.password?.message}</p>
                      )}
                    </CCol>
                    <CCol sm={6}>
                      <CFormLabel htmlFor="confirmPassword" className="col-sm-3 col-form-label">
                        Confirm Password:
                      </CFormLabel>
                      <CFormInput
                        type="password"
                        id="confirmPassword"
                        {...register("confirmPassword")}
                      />
                      {errors.confirmPassword?.message && (
                        <p className="error_text">{errors.confirmPassword?.message}</p>
                      )}
                    </CCol>
                  </CRow>
                </>
              )}

              <CRow className="mt-5">
                <CCol sm={12} className="text-end">
                  <CButton type="submit" color="primary">
                    Submit
                  </CButton>
                </CCol>
              </CRow>
            </div>
          </div>
        </div>
      </div>
    </CForm>
  );
};

export default UserProfile;
