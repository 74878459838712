import React, { useEffect, useState } from "react";
import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { CChartBar, CChartDoughnut, CChartLine } from "@coreui/react-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload } from "@coreui/icons";
import { getFileReport } from "src/services/report.services";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import GoBackSvg from "src/assets/svg/GoBackSvg";

const FileReport = () => {
  const { id } = useParams();
  const filterBy = ["Day", "Month", "Year"];
  const [selectedFilter, setSelectedFilter] = useState("Month");
  const [reportParams, setReportParams] = useState({
    from: "2023-01",
    to: "2023-12",
    type: selectedFilter,
  });
  const [dayFromDate, setDayFromDate] = useState();
  const [dayToDate, setDayToDate] = useState();
  const [monthFromDate, setMonthFromDate] = useState("01");
  const [monthToDate, setMonthToDate] = useState("12");
  const [monthYear, setMonthYear] = useState(2023);
  const [yearFrom, setYearFrom] = useState(2023);
  const [yearTo, setYearTo] = useState(2023);
  const [fileDetails, setFileDetails] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [filesArray, setFilesArray] = useState([]);
  const [graphHeadArray, setGraphHeadArray] = useState([]);
  const [graphAllDataCount, setGraphAllDataCount] = useState([]);
  const [graphAssessResponsePattersDataCount, setGraphAssessResponsePattersDataCount] = useState(
    [],
  );
  const [graphCrossTabulationDataCount, setGraphCrossTabulationDataCount] = useState([]);
  const [graphDuplicateRespondentDataCount, setGraphDuplicateRespondentDataCount] = useState([]);
  const [graphFilteredDataCount, setGraphFilteredDataCount] = useState([]);
  const [graphIdentifyOutliersDataCount, setGraphIdentifyOutliersDataCount] = useState([]);
  const [
    graphIpAddressDuplicateRespondentsDataCount,
    setGraphIpAddressDuplicateRespondentsDataCount,
  ] = useState([]);
  const [graphIpAddressLocationCheckDataCount, setGraphIpAddressLocationCheckDataCount] = useState(
    [],
  );
  const [graphMissingDataCount, setGraphMissingDataCount] = useState([]);
  const [graphValidateDataRangeDataCount, setGraphValidateDataRangeDataCount] = useState([]);
  const [graphValidateOpenEndedResponseDataCount, setGraphValidateOpenEndedResponseDataCount] =
    useState([]);

  const navigate = useNavigate();

  const monthNameObj = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  const yearOption = [
    { label: 2023, value: 2023 },
    { label: 2024, value: 2024 },
  ];
  const monthOption = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  const {
    data: fileReport,
    isLoading: isLoading,
    onError: isUserDataError,
  } = useQuery(["reports", id, reportParams], () => getFileReport(id, reportParams), {
    // staleTime: 1000 * 60 * 5,
    enabled: !!id && !!reportParams,
    refetchOnWindowFocus: false,
  });

  const reportGraphDataLabel = {
    allDataCount: {
      label: "All Data",
      color: "success",
      column: "allData",
    },
    assessResponsePattersDataCount: {
      label: "Flat Liners",
      color: "info",
      column: "assessResponsePattersData",
    },
    crossTabulationDataCount: {
      label: "cross Tabulation And Consistency",
      color: "warning",
      column: "crossTabulationData",
    },
    duplicateRespondentDataCount: {
      label: "Duplicate Respondents(BOTS)",
      color: "primary",
      column: "duplicateRespondentData",
    },
    filteredDataCount: {
      label: "Filtered Data",
      color: "primary",
      column: "filteredData",
    },
    identifyOutliersDataCount: {
      label: "Identify Outliers",
      color: "info",
      column: "identifyOutliersData",
    },
    ipAddressDuplicateRespondentsDataCount: {
      label: "Duplicate Respondents(IP Address)",
      color: "primary",
      column: "ipAddressDuplicateRespondentsData",
    },
    ipAddressLocationCheckDataCount: {
      label: "Location Check",
      color: "warning",
      column: "ipAddressLocationCheckData",
    },
    missingDataCount: {
      label: "Missing Data",
      color: "warning",
      column: "missingData",
    },
    validateDataRangeDataCount: {
      label: "Data Range Validation",
      color: "info",
      column: "validateDataRangeData",
    },
    validateOpenEndedResponseDataCount: {
      label: "Open Ended Response",
      color: "warning",
      column: "validateOpenEndedResponseData",
    },
  };

  useEffect(() => {
    if (fileReport && fileReport?.data) {
      const fileReportData = fileReport.data;

      const totalDataCount = fileReportData.allDataCount;
      let tableData = [];
      let graphHead = [];
      let graphData = [];
      for (const key in fileReportData) {
        if (key != "_id" && reportGraphDataLabel[key]) {
          const obj = {
            name: reportGraphDataLabel[key]["label"],
            // selectedColumns: reportGraphDataLabel[key]["column"]
            //   ? fileReportData[reportGraphDataLabel[key]["column"]]["selectedColumn"]
            //     ? fileReportData[reportGraphDataLabel[key]["column"]]["selectedColumn"]
            //     : []
            //   : [],
            measurement: fileReportData[key],
            percentage: ((100 * fileReportData[key]) / totalDataCount).toFixed(2),
            color: reportGraphDataLabel[key]["color"],
          };
          tableData.push(obj);
        }

        if (key != "allDataCount" && reportGraphDataLabel[key]) {
          graphHead.push(reportGraphDataLabel[key]["label"]);
          graphData.push(fileReportData[key]);
        }
      }
      setFileDetails(tableData);
      setGraphHeadArray(graphHead);
      setFilesArray(graphData);

      // let files = [];
      // let allDataCount = [];
      // let assessResponsePattersDataCount = [];
      // let crossTabulationDataCount = [];
      // let duplicateRespondentDataCount = [];
      // let filteredDataCount = [];
      // let identifyOutliersDataCount = [];
      // let ipAddressDuplicateRespondentsDataCount = [];
      // let ipAddressLocationCheckDataCount = [];
      // let missingDataCount = [];
      // let validateDataRangeDataCount = [];
      // let validateOpenEndedResponseDataCount = [];

      // projectReportObj.forEach((element) => {
      //   if (selectedFilter == "Month") {
      //     graphHead.push(monthNameObj[Number(element.Date)]);
      //   } else {
      //     graphHead.push(element.Date);
      //   }

      //   files.push(Number(element.files));
      //   allDataCount.push(element.allDataCount);
      //   assessResponsePattersDataCount.push(element.assessResponsePattersDataCount);
      //   crossTabulationDataCount.push(element.crossTabulationDataCount);
      //   duplicateRespondentDataCount.push(element.duplicateRespondentDataCount);
      //   filteredDataCount.push(element.filteredDataCount);
      //   identifyOutliersDataCount.push(element.identifyOutliersDataCount);
      //   ipAddressDuplicateRespondentsDataCount.push(element.ipAddressDuplicateRespondentsDataCount);
      //   ipAddressLocationCheckDataCount.push(element.ipAddressLocationCheckDataCount);
      //   missingDataCount.push(element.missingDataCount);
      //   validateDataRangeDataCount.push(element.validateDataRangeDataCount);
      //   validateOpenEndedResponseDataCount.push(element.validateOpenEndedResponseDataCount);
      // });

      // setGraphHeadArray(graphHead);
      // setUploadedFilesArray(files);
      // setGraphAllDataCount(allDataCount);
      // setGraphAssessResponsePattersDataCount(assessResponsePattersDataCount);
      // setGraphCrossTabulationDataCount(crossTabulationDataCount);
      // setGraphDuplicateRespondentDataCount(duplicateRespondentDataCount);
      // setGraphFilteredDataCount(filteredDataCount);
      // setGraphIdentifyOutliersDataCount(identifyOutliersDataCount);
      // setGraphIpAddressDuplicateRespondentsDataCount(ipAddressDuplicateRespondentsDataCount);
      // setGraphIpAddressLocationCheckDataCount(ipAddressLocationCheckDataCount);
      // setGraphMissingDataCount(missingDataCount);
      // setGraphValidateDataRangeDataCount(validateDataRangeDataCount);
      // setGraphValidateOpenEndedResponseDataCount(validateOpenEndedResponseDataCount);
    }
  }, [fileReport]);

  const handleDayReportSubmit = (e) => {
    e.preventDefault();
    if (selectedFilter === "Day") {
      const params = { from: dayFromDate, to: dayToDate, type: selectedFilter };
      setReportParams(params);
    } else if (selectedFilter === "Month") {
      const fromDate = monthYear + "-" + monthFromDate;
      const toDate = monthYear + "-" + monthToDate;
      const params = { from: fromDate, to: toDate, type: selectedFilter };
      setReportParams(params);
    } else {
      const fromDate = yearFrom;
      const toDate = yearTo;
      const params = { from: fromDate, to: toDate, type: selectedFilter };
      setReportParams(params);
    }
  };

  const handleDownload = () => {
    const fileName = "file-report";
    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fileDetails);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a binary XLSX file and save it
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <div className="bg-white mb-4">
      <CCard className="rounded-0 border-0">
        <CCardBody>
          <CRow className="align-items-center">
            <CCol sm={12}>
              <div className="campaign-card-back">
                <CButton
                  color="light"
                  variant="ghost"
                  className="text-uppercase px-0"
                  onClick={() => navigate(-1)}>
                  <GoBackSvg /> Back
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard className="border-0 border-bottom rounded-0 bg-gray-50 mb-4">
        <CCardBody className="campaign-card">
          <CRow className="align-items-center">
            <CCol sm={9}>
              <h4 id="traffic" className="card-title mb-0">
                File Detail
              </h4>
            </CCol>
            <CCol sm={3}>
              <CButton color="primary" className="float-end" onClick={handleDownload}>
                <CIcon icon={cilCloudDownload} />
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <div className="p-3">
        <CTable
          align="middle"
          className="table table-striped table-bordered table-sm main-data-table mb-0"
          striped
          responsive>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell className="p-3">Filter Name</CTableHeaderCell>
              {/* <CTableHeaderCell className="p-3">Column Names</CTableHeaderCell> */}
              <CTableHeaderCell className="p-3">Measurement</CTableHeaderCell>
              <CTableHeaderCell className="p-3">Percentage</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {isLoading ? (
              <CTableRow className="text-center">
                <CTableDataCell colspan="6">Loading...</CTableDataCell>
              </CTableRow>
            ) : (
              fileDetails.map((item, index) => (
                <CTableRow v-for="item in tableItems" key={index}>
                  <CTableDataCell>{item.name}</CTableDataCell>
                  {/* <CTableDataCell>
                        {item.selectedColumns.length > 0 ? item.selectedColumns.join(",") : ""}
                      </CTableDataCell> */}
                  <CTableDataCell>{item.measurement}</CTableDataCell>
                  <CTableDataCell>{item.percentage}</CTableDataCell>
                </CTableRow>
              ))
            )}
          </CTableBody>
        </CTable>
      </div>
      <div className="p-3">
        <CCard className="rounded-3 border-0 mb-4">
          <CCardHeader className="p-3 bg-transparent border-0">
            <h5 className="report-title pt-3">Filtered Files Report</h5>
          </CCardHeader>
          <CCardBody>
            <CChartBar
              data={{
                labels: graphHeadArray,
                datasets: [
                  {
                    label: "Total Files",
                    backgroundColor: "#f87979",
                    data: filesArray,
                  },
                ],
              }}
              labels="months"
            />
          </CCardBody>
        </CCard>
      </div>
    </div>
  );
};

export default FileReport;
