const FilterTypesHeader = (filterTypes, shortHeader = false) => {
  if (shortHeader) {
    switch (filterTypes) {
      case "CHECK_FOR_MISSING_DATA":
        return "Missing Data";
      case "VALIDATE_DATA_RANGE":
        return "Data Range";
      case "IDENTIFY_OUTLIERS":
        return "Outliers";
      case "CROSS_TABULATION":
        return "Cross-Tabulation";
      case "ASSES_RESPONSE_PATTERNS":
        return "Response Patterns";
      case "VALIDATE_OPEN_ENDED_RESPONSES":
        return "Open-Ended Responses";
      case "DUPLICATE_RESPONDENTS":
        return "Duplicate Respondents";
      case "IP_ADDRESS_CHECK":
        return "IP Address Check";
      case "IP_ADDRESS_CHECK_LOCATION":
        return "IP Address Location";
      case "MULTI_CODE_LOGIC":
        return "Multi Code Logic";
      default:
        return "Apply Filters";
    }
  }
  switch (filterTypes) {
    case "CHECK_FOR_MISSING_DATA":
      return "Check For Missing Data";
    case "VALIDATE_DATA_RANGE":
      return "Validate Data Range";
    case "IDENTIFY_OUTLIERS":
      return "Identify Outliers";
    case "CROSS_TABULATION":
      return "Cross-Tabulation And Consistency Checks";
    case "ASSES_RESPONSE_PATTERNS":
      return "Assess Response Patterns (Flat Liners)";
    case "VALIDATE_OPEN_ENDED_RESPONSES":
      return "Validate Open-Ended Responses";
    case "DUPLICATE_RESPONDENTS":
      return "Duplicate Respondents (BOTS)";
    case "IP_ADDRESS_CHECK":
      return "IP Address Check (Duplicate Respondents)";
    case "IP_ADDRESS_CHECK_LOCATION":
      return "IP Address Check (Location Checker)";
    case "MULTI_CODE_LOGIC":
      return "Multi Code Logic";
    default:
      return "Apply Filters";
  }
};

const removeHiddenRows = (rows, hiddenData) => {
  const allHiddenRows = Object.values(hiddenData).flat();
  const allHiddenRowsUniqueIds = allHiddenRows.map((row) => row.uniqueId);

  const newRows = rows.filter((row) => {
    if (allHiddenRowsUniqueIds.includes(row.uniqueId)) {
      return false;
    } else {
      return true;
    }
  });
  return newRows;
};

function delay(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

export { FilterTypesHeader, removeHiddenRows, delay };
