import { FilterTypes } from "./commontypes";
import { removeHiddenRows } from "./helpers/commonHelper";

const outlierDetector = (data) => {
  // Sort the data in ascending order
  data.sort((a, b) => a - b);

  // Calculate the first quartile (Q1) and third quartile (Q3)
  const Q1Index = Math.floor(data.length * 0.25);
  const Q3Index = Math.floor(data.length * 0.75);
  const Q1 = data[Q1Index];
  const Q3 = data[Q3Index];

  // Calculate the Interquartile Range (IQR)
  const IQR = Q3 - Q1;

  // Define the lower and upper bounds for outliers
  const lowerBound = Q1 - 1.5 * IQR;
  const upperBound = Q3 + 1.5 * IQR;

  // Find outliers
  const outliers = data.filter((value) => value < lowerBound || value > upperBound);

  return outliers;
};

const handleFilterIdentifyOutliers = (
  columns,
  rows,
  selectedColumns,
  hiddenData,
  setHiddenData,
  filterTypes,
) => {
  const _hiddenData = { ...hiddenData };
  _hiddenData[FilterTypes.IDENTIFY_OUTLIERS] = [];
  const _selectedColumns = selectedColumns[filterTypes];
  for (let i = 0; i < _selectedColumns.length; i++) {
    const column = _selectedColumns[i];
    let values = [];
    for (let j = 0; j < rows.length; j++) {
      if (rows[j][columns[column]] !== undefined && !isNaN(rows[j][columns[column]])) {
        values.push(rows[j][columns[column]]);
      }
    }
    console.log({ values });
    values = outlierDetector(values);
    for (let j = 0; j < rows.length; j++) {
      if (rows[j][columns[column]] !== undefined && !isNaN(rows[j][columns[column]])) {
        if (values.includes(Number(rows[j][columns[column]]))) {
          _hiddenData[FilterTypes.IDENTIFY_OUTLIERS].push(rows[j]);
        }
      }
    }
  }
  setHiddenData(_hiddenData);
  const result = removeHiddenRows(rows, _hiddenData);
  return { _hiddenData, result };
};

export { handleFilterIdentifyOutliers };
