import React, { useRef, useState, useEffect } from "react";
import {
  CAvatar,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CToaster,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilPeople, cilPlus, cilTrash } from "@coreui/icons";
import CustomPagination from "../../components/CustomPagination";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import toast from "react-hot-toast";
import { deleteUser, getAllUser } from "src/services/user.services";

const UserManagementTable = () => {
  const toaster = useRef();
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery(["users"], getAllUser, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const { mutate: deleteUserMutate, isLoading: deleteUserIsLoading } = useMutation(deleteUser, {
    onSuccess: () => {
      toast.dismiss();
      queryClient.invalidateQueries("users");
      toast.success("User deleted successfully");
    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err.message || "Something went wrong");
    },
  });

  if (isLoading) {
    toast.dismiss();
    toast.loading("Loading...");
  }

  if (deleteUserIsLoading) {
    toast.dismiss();
    toast.loading("Deleting user...");
  }

  if (isError) {
    toast.dismiss();
    toast.error("Error fetching user list");
  }

  if (data) {
    toast.dismiss();
  }

  const submitDelete = () => {
    deleteUserMutate(deleteId);
  };

  const handleDelete = (id) => {
    setVisibleDeleteModal(true);
    setDeleteId(id);
  };

  return (
    <>
      <CCard className="rounded-0 border-0 bg-gray-50">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                User Management
              </h4>
            </CCol>
            <CCol sm={7} className="d-none d-md-block">
              <div className="d-flex justify-content-end">
                <Link to="/create-user">
                  <CButton color="primary">
                    <CIcon icon={cilPlus} />
                    &nbsp; Create
                  </CButton>
                </Link>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard className="user-profile-card rounded-0 border-0">
        <CCardBody>
          <CTable
            className="table table-striped table-bordered table-sm main-data-table mb-0"
            responsive>
            <CTableHead>
              <CTableRow>
                {/* <CTableHeaderCell className="text-center">
                  <CIcon icon={cilPeople} />
                </CTableHeaderCell> */}
                <CTableHeaderCell>Name</CTableHeaderCell>
                <CTableHeaderCell>Email</CTableHeaderCell>
                <CTableHeaderCell>User Type</CTableHeaderCell>
                <CTableHeaderCell>Created At</CTableHeaderCell>
                {/* <CTableHeaderCell>Permission</CTableHeaderCell> */}
                <CTableHeaderCell>Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {data &&
                data?.data?.length > 0 &&
                data.data.map((item, index) => (
                  <CTableRow v-for="item in tableItems" key={index}>
                    {/* <CTableDataCell className="text-center">
                      <CAvatar size="md" src={item.avatar} status="suucess" />
                    </CTableDataCell> */}
                    <CTableDataCell>
                      <div>
                        {item.firstName} {item.lastName}
                      </div>
                    </CTableDataCell>
                    <CTableDataCell>{item.email}</CTableDataCell>
                    <CTableDataCell>{item.userType}</CTableDataCell>
                    <CTableDataCell>{new Date(item.createdAt).toLocaleDateString()}</CTableDataCell>
                    {/* <CTableDataCell>
                      <div className="user-management-table-permission">
                        <div>
                          <span>Add Project</span> : {item.permission.addProject ? "Yes" : "No"}
                        </div>
                        <div>
                          {" "}
                          <span>Edit Project</span> : {item.permission.editProject ? "Yes" : "No"}
                        </div>
                        <div>
                          <span>Delete Project</span> :{" "}
                          {item.permission.deleteProject ? "Yes" : "No"}
                        </div>
                        <div>
                          <span>Add File</span> : {item.permission.addFile ? "Yes" : "No"}
                        </div>
                        <div>
                          {" "}
                          <span>Upload File</span>: {item.permission.uploadFile ? "Yes" : "No"}
                        </div>
                        <div>
                          {" "}
                          <span>Edit File</span>: {item.permission.editFile ? "Yes" : "No"}
                        </div>
                        <div>
                          <span>Delete File</span>: {item.permission.deleteFile ? "Yes" : "No"}
                        </div>
                      </div>
                    </CTableDataCell> */}
                    <CTableDataCell>
                      <div className="d-flex gap-2">
                        <Link to={`/edit-user/${item._id}`}>
                          <CButton
                            type="button"
                            size="sm"
                            color="light"
                            className="table-btn text-primary">
                            <FaEdit width={14} />
                          </CButton>
                        </Link>

                        <CButton
                          onClick={() => handleDelete(item._id)}
                          type="button"
                          size="sm"
                          color="danger"
                          className="table-btn text-white">
                          <FaTrash width={14} />
                        </CButton>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                ))}
            </CTableBody>
          </CTable>
          <br />
          {/* <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          /> */}
        </CCardBody>
      </CCard>
      {/* <DeleteModal
        visible={visibleDeleteModal}
        setVisibleDeleteModal={setVisibleDeleteModal}
        // addToast={addToast}
      /> */}
      {/* <CToaster ref={toaster} push={toast} placement="top-end" /> */}
      <DeleteModal
        visibleDeleteModal={visibleDeleteModal}
        setVisibleDeleteModal={setVisibleDeleteModal}
        submitDelete={submitDelete}
      />
    </>
  );
};

export default UserManagementTable;
