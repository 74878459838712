import React, { useRef, useState, useEffect } from "react";
import { getUserList } from "../../services/user.services";
import toast from "react-hot-toast";
import {
  CAvatar,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CToaster,
  CButton,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilPeople, cilPencil, cilPlus, cilTrash } from "@coreui/icons";
import CustomPagination from "../../components/CustomPagination";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import avatar1 from "src/assets/images/avatars/1.jpg";
import avatar2 from "src/assets/images/avatars/2.jpg";
import avatar3 from "src/assets/images/avatars/3.jpg";
import nofiles from "src/assets/images/no-files.png";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import FileSvg from "src/assets/svg/FileSvg";
import CreateCampaignModal from "./CreateCampaignModal";
import SearchIconSvg from "src/assets/svg/SearchIconSvg";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteProject,
  deleteSharedProject,
  getAllProject,
  getAllSharedProject,
  getSharedProject,
} from "src/services/project.services";
import { useDebounce } from "use-debounce";
import EyeIconSvg from "src/assets/svg/EyeIconSvg";
import ReportSvg from "src/assets/svg/ReportSvg";
import SHareCampaignModal from "./ShareCampaignModal";
import SharedWithCampaignModal from "./SharedWithCampaignModal";

const CampaignManagementTable = () => {
  const userInfo = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
  const toaster = useRef();
  const [isCreateCampaignModal, setIsCreateCampaignModal] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [sharedPage, setSharedPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [searchString, setSearchString] = useState("");
  const [debouncedValue] = useDebounce(searchString, 700);
  const [projectType, setProjectType] = useState("myProject");
  const [isCampaignShareModal, setIsCampaignShareModal] = useState(false);
  const [sharedProject, setSharedProject] = useState(false);
  const [sharedToPage, setSharedToPage] = useState(1);
  const [isSharedWithCampaignModal, setIsSharedWithCampaignModal] = useState(false);
  const [selectedSharedWithProject, setSelectedSharedWithProject] = useState(null);
  const [isSharedCancel, setIsSharedCancel] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    isLoading: isProjectLoading,
    isError: isProjectError,
    data: allProjectData,
  } = useQuery(
    ["projects", page, limit, debouncedValue, projectType],
    () => getAllProject({ page, limit, searchString: debouncedValue }),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      enabled: projectType === "myProject",
    },
  );

  const {
    isLoading: isGetALlSharedProjectLoading,
    isError: isGetALlSharedProjectError,
    data: allSharedProjectData,
  } = useQuery(
    ["projects", sharedPage, limit, projectType],
    () => getAllSharedProject({ page: sharedPage, limit }),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      enabled: projectType === "sharedProject",
    },
  );

  const {
    isLoading: isGetAllSharedToProjectLoading,
    isError: isGetAllSharedToProjectError,
    data: allSharedToProjectData,
  } = useQuery(
    ["projects", sharedToPage, limit, projectType],
    () => getSharedProject({ page: sharedToPage, limit }),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      enabled: projectType === "sharedToProject",
    },
  );
  const { mutate: deleteProjectMutate, isLoading: deleteProjectIsLoading } = useMutation(
    deleteProject,
    {
      onSuccess: () => {
        toast.dismiss();
        queryClient.invalidateQueries("projects");
        toast.success("Project deleted successfully");
      },
      onError: (err) => {
        toast.dismiss();
        toast.error(err?.message || "Error deleting project");
      },
    },
  );

  const { mutate: deleteSharedProjectMutate, isLoading: isDeleteSharedProjectLoading } =
    useMutation(deleteSharedProject, {
      onSuccess: (data) => {
        toast.dismiss();
        setIsSharedCancel(true);
        queryClient.invalidateQueries("projects");
        toast.success("Project Sharing canceled successfully");
      },
      onError: () => {
        toast.dismiss();
        toast.error("Error canceling project sharing");
      },
    });

  const handleCampaign = (name, id) => {
    navigate("/projects/" + name + "/" + id);
  };

  const handleCreateCampaign = () => {
    setProjectId(null);
    setIsCreateCampaignModal(true);
  };
  const handleProjectDelete = (id) => {
    setProjectId(id);
    setVisibleDeleteModal(true);
  };

  const handleProjectEdit = (id) => {
    setIsCreateCampaignModal(true);
    setProjectId(id);
  };

  if (isGetALlSharedProjectError) {
    toast.dismiss();
    toast.error("Error loading projects");
  }
  if (isGetALlSharedProjectLoading && projectType === "sharedProject") {
    toast.dismiss();
    toast.loading("Loading...");
  }
  if (allSharedProjectData) {
    toast.dismiss();
  }

  if (isGetAllSharedToProjectError) {
    toast.dismiss();
    toast.error("Error loading projects");
  }
  if (isGetAllSharedToProjectLoading && projectType === "sharedToProject") {
    toast.dismiss();
    toast.loading("Loading...");
  }
  if (allSharedToProjectData) {
    toast.dismiss();
  }

  // if (isDeleteSharedProjectLoading) {
  //   toast.loading("Canceling your share...");
  // }

  if (isProjectError) {
    toast.dismiss();
    toast.error("Error loading projects");
  }

  if (deleteProjectIsLoading) {
    toast.dismiss();
    toast.loading("Deleting...");
  }
  if (isProjectLoading && projectType === "myProject") {
    toast.dismiss();
    toast.loading("Loading...");
  }
  if (allProjectData) {
    toast.dismiss();
  }

  const submitDelete = () => {
    deleteProjectMutate(projectId);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleSharedPageChange = (page) => {
    setSharedPage(page);
  };

  const handleSharedToPageChange = (page) => {
    setSharedToPage(page);
  };

  const handleSearch = (value) => {
    setSearchString(value);
    setPage(1);
    setSharedPage(1);
  };

  const handleReport = (id) => {
    navigate("/report/campaign/" + id);
  };

  const handleShareCampaign = (project) => {
    setSharedProject(project);
    setIsCampaignShareModal(true);
  };

  const handleProjectShareDelete = (data) => {
    deleteSharedProjectMutate(data._id);
  };

  const updateSharedUserData = (data) => {
    // const selectedProject = sharedProject;
    // let sharedInfo = selectedProject.share;
    // sharedInfo.push(data);
    // selectedProject.share = sharedInfo;
    // setSharedProject(selectedProject);
  };

  const handleSharedWith = (project) => {
    setSelectedSharedWithProject(project);
    setIsSharedWithCampaignModal(true);
  };

  return (
    <>
      <CCard className="border-0 bg-gray-50 rounded-0 border-bottom mb-2">
        <CCardBody className="campaign-card">
          <CRow className="align-items-center mb-3">
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                Project Management
              </h4>
            </CCol>
          </CRow>

          <CRow>
            <div className="col-lg-9">
              <ul className="nav nav-underline apply-filters-filter-headers bg-transparent">
                <li onClick={() => setProjectType("myProject")} className="nav-item">
                  <a
                    className={`nav-link ${projectType === "myProject" ? "active" : ""}`}
                    aria-current="page">
                    My Projects
                  </a>
                </li>
                <li onClick={() => setProjectType("sharedProject")} className="nav-item">
                  <a className={`nav-link ${projectType === "sharedProject" ? "active" : ""}`}>
                    Shared Projects
                  </a>
                </li>
                {/* <li onClick={() => setProjectType("sharedToProject")} className="nav-item">
                  <a className={`nav-link ${projectType === "sharedToProject" ? "active" : ""}`}>
                    My Sharing Projects
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="col-lg-3">
              <div className="d-flex justify-content-end gap-3">
                {projectType === "myProject" && (
                  <div className="dc-searchbox input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Project"
                      aria-label="Search Project"
                      aria-describedby="Serach Project"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button className="btn btn-outline-secondary search-icon-btn" type="button">
                        <SearchIconSvg />
                      </button>
                    </div>
                  </div>
                )}

                {userInfo?.permission?.addProject ? (
                  <div className="d-flex justify-content-end">
                    <CButton onClick={handleCreateCampaign} color="primary" className="text-nowrap">
                      <CIcon icon={cilPlus} />
                      Create Project
                    </CButton>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <CCol sm={2} className="d-none d-md-block"></CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <br />

      {/* <br /> */}

      {/* {projectType === "sharedToProject" &&
        allSharedToProjectData &&
        allSharedToProjectData.data &&
        allSharedToProjectData.data.length > 0 &&
        allSharedToProjectData.data.map((data, index) => {
          return (
            <CCard key={index} className="rounded-3 border-0 mb-2">
              <CCardBody className="projects-wrapper">
                <CRow>
                  <CCol
                    className="projects"
                    onClick={() => handleCampaign(data.Project.name, data.Project._id)}
                    sm={5}>
                    <div className="campaign-card-title">
                      <h4 id="traffic" className="card-title mb-0 ml-4">
                        {data.Project.name}
                      </h4>
                    </div>
                  </CCol>
                  <CCol sm={7} className="d-none d-md-block">
                    <div className="d-flex justify-content-end align-items-center gap-4">
                      <div>
                        <p className="mb-1 text-gray">Shared To:</p>
                        <p className="mb-1 font-bold">
                          {data.sharedTo.firstName + data.sharedTo.lastName || ""}
                        </p>
                      </div>
                      <div>
                        <p className="mb-1 text-gray">Shared Time:</p>
                        <p className="mb-1 font-bold">
                          {new Date(data.createdAt).toLocaleDateString()}
                        </p>
                      </div>
                      <CButton
                        onClick={() => handleProjectShareDelete(data)}
                        className="btn btn-sm btn-danger">
                        Cancel Share
                      </CButton>
                    </div>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          );
        })} */}

      {projectType === "sharedProject" &&
        allSharedProjectData &&
        allSharedProjectData.data &&
        allSharedProjectData.data.length > 0 &&
        allSharedProjectData.data.map((data, index) => {
          return (
            <div key={index} className="px-3">
              <CCard className="project-list-card rounded-3 border mb-2">
                <CCardBody>
                  <CRow>
                    <CCol
                      className="projects"
                      onClick={() => handleCampaign(data.Project.name, data.Project._id)}
                      sm={5}>
                      <div className="campaign-card-title">
                        <h4 id="traffic" className="card-title mb-0 ml-4">
                          {data.Project.name}
                        </h4>
                        <div className="mb-1 text-dark font-bold">
                          Survey id:
                          {data?.Project?.projectIdentifier && (
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                //copy to clipboard
                                navigator.clipboard.writeText(data?.Project?.projectIdentifier);
                                toast.success("Project Identifier Copied to Clipboard");
                              }}>
                              {data?.Project?.projectIdentifier || ""}
                            </span>
                          )}
                        </div>
                      </div>
                    </CCol>
                    <CCol sm={7} className="d-none d-md-block">
                      <div className="project-details-body d-flex justify-content-end align-items-center gap-5">
                        <div className="project-details d-flex justify-content-end align-items-center gap-5">
                          <div className="project-details-list file">
                            <p className="mb-1 text-gray">Total File</p>
                            <p className="mb-1 font-bold">{data.Project.totalFile}</p>
                          </div>
                          <div className="project-details-list date">
                            <p className="mb-1 text-gray">Created Date</p>
                            <p className="mb-1 font-bold">
                              {new Date(data.Project.createdAt).toLocaleDateString()}
                            </p>
                          </div>

                          <div className="project-details-list created-by">
                            <p className="mb-1 text-gray">Shared By</p>
                            <p className="mb-1 font-bold">
                              {data.sharedBy.firstName + " " + data.sharedBy.lastName || ""}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex gap-2">
                          <CButton
                            type="button"
                            onClick={() => handleReport(data.Project._id)}
                            color="light">
                            Report
                          </CButton>
                          {/* <CDropdown>
                          <CDropdownToggle color="primary">Actions</CDropdownToggle>
                          <CDropdownMenu>
                            <CDropdownItem className="cursor-pointer">
                              Download as PDF
                            </CDropdownItem>
                            <CDropdownItem className="cursor-pointer">
                              Download as Excel
                            </CDropdownItem>
                          </CDropdownMenu>
                        </CDropdown> */}
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </div>
          );
        })}

      {projectType === "myProject" &&
        allProjectData &&
        allProjectData.data &&
        allProjectData.data.length > 0 &&
        allProjectData.data.map((project, index) => {
          return (
            <div key={index} className="px-3">
              <CCard className="project-list-card rounded-3 border-2 border-light mb-2">
                <CCardBody>
                  <CRow>
                    <CCol
                      className="projects"
                      onClick={() => handleCampaign(project.name, project._id)}
                      sm={5}>
                      <div className="campaign-card-title">
                        <h4 id="traffic" className="card-title mb-0 ml-4">
                          {project.name}
                        </h4>
                        <div className="dc-quite-text-style mb-1 text-dark font-bold">
                          Survey id:
                          {project.projectIdentifier && (
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                //copy to clipboard
                                navigator.clipboard.writeText(project.projectIdentifier);
                                toast.success("Project Identifier Copied to Clipboard");
                              }}>
                              {project.projectIdentifier || ""}
                            </span>
                          )}
                        </div>
                      </div>
                    </CCol>
                    <CCol sm={7} className="d-none d-md-block">
                      <div className="project-details-body d-flex justify-content-end align-items-center gap-5">
                        <div className="project-details d-flex justify-content-end align-items-center gap-5">
                          <div className="project-details-list file">
                            <p className="dc-quite-text-style mb-1 text-gray text-uppercase text-nowrap">
                              Total File
                            </p>
                            <p className="mb-1 font-bold">{project.totalFile}</p>
                          </div>
                          <div className="project-details-list date">
                            <p className="dc-quite-text-style mb-1 text-gray text-uppercase text-nowrap">
                              Created Date
                            </p>
                            <p className="mb-1 font-bold text-nowrap">
                              {new Date(project.createdAt).toLocaleDateString()}
                            </p>
                          </div>
                          <div className="project-details-list created-by">
                            <p className="dc-quite-text-style mb-1 text-gray text-uppercase text-nowrap">
                              Created By
                            </p>
                            <p className="mb-1 font-bold">
                              {project.createdBy.firstName + " " + project.createdBy.lastName || ""}
                            </p>
                          </div>

                          <div className="project-details-list shared-to">
                            <p className="dc-quite-text-style mb-1 text-gray text-uppercase text-nowrap">
                              Shared To
                            </p>
                            <p className="mb-1 font-bold">{project.share.length}</p>
                          </div>
                        </div>

                        <div className="d-flex gap-2">
                          <CButton
                            type="button"
                            onClick={() => handleReport(project._id)}
                            color="light"
                            className="text-primary">
                            Report
                          </CButton>
                          <CDropdown>
                            <CDropdownToggle color="primary">Actions</CDropdownToggle>
                            <CDropdownMenu>
                              {/* <CDropdownItem className="cursor-pointer">Download as PDF</CDropdownItem>
                          <CDropdownItem className="cursor-pointer">
                            Download as Excel
                          </CDropdownItem> */}
                              <CDropdownItem
                                onClick={() => handleShareCampaign(project)}
                                className="cursor-pointer">
                                Share
                              </CDropdownItem>
                              <CDropdownItem
                                onClick={() => handleSharedWith(project)}
                                className="cursor-pointer">
                                Shared With
                              </CDropdownItem>
                              {userInfo?.permission?.editProject ? (
                                <CDropdownItem
                                  className="cursor-pointer"
                                  onClick={() => handleProjectEdit(project._id)}>
                                  Edit
                                </CDropdownItem>
                              ) : (
                                ""
                              )}
                              {userInfo?.permission?.deleteProject ? (
                                <CDropdownItem
                                  className="cursor-pointer"
                                  onClick={() => handleProjectDelete(project._id)}>
                                  Delete
                                </CDropdownItem>
                              ) : (
                                ""
                              )}
                            </CDropdownMenu>
                          </CDropdown>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </div>
          );
        })}

      {(!allProjectData || allProjectData?.data?.length <= 0) && projectType === "myProject" && (
        <div className="px-3">
          <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <div className="nofiles-share">
              <img src={nofiles} alt="nofiles" />
            </div>
            <h4 className="nofiles-share-title">No Project Found!</h4>
          </div>
        </div>
      )}

      {(!allSharedProjectData || allSharedProjectData?.data?.length <= 0) &&
        projectType === "sharedProject" && (
          <div className="px-3">
            <div className="d-flex flex-column justify-content-center align-items-center mt-5 mb-5">
              <div className="nofiles-share">
                <img src={nofiles} alt="nofiles" />
              </div>
              <h4 className="nofiles-share-title">No Shared Project Found!</h4>
            </div>
          </div>
        )}

      {(!allSharedToProjectData || allSharedToProjectData?.data?.length <= 0) &&
        projectType === "sharedToProject" && (
          <div className="px-3">
            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
              <div className="nofiles-share">
                <img src={nofiles} alt="nofiles" />
              </div>
              <h4 className="nofiles-share-title">No Sharing Project Found!</h4>
            </div>
          </div>
        )}
      {allProjectData &&
        allProjectData.data &&
        allProjectData.data.length > 0 &&
        projectType === "myProject" && (
          <div className="p-3">
            <CustomPagination
              currentPage={page || 1}
              totalPages={Math.ceil(allProjectData?.count / 5) || 1}
              onPageChange={handlePageChange}
            />
          </div>
        )}

      {allSharedProjectData &&
        allSharedProjectData.data &&
        allSharedProjectData.data.length > 0 &&
        projectType === "sharedProject" && (
          <div className="p-3">
            <CustomPagination
              currentPage={sharedPage || 1}
              totalPages={Math.ceil(allSharedProjectData?.count / 5) || 1}
              onPageChange={handleSharedPageChange}
            />
          </div>
        )}

      {allSharedToProjectData &&
        allSharedToProjectData.data &&
        allSharedToProjectData.data.length > 0 &&
        projectType === "sharedToProject" && (
          <div className="p-3">
            <CustomPagination
              currentPage={sharedToPage || 1}
              totalPages={Math.ceil(allSharedToProjectData?.count / 5) || 1}
              onPageChange={handleSharedToPageChange}
            />
          </div>
        )}
      {/* <div onClick={handleCampaign} class="campaignCard">
          <FileSvg />
          <div>
            <h5>Twitter Users</h5>
            <span class="data-count">Total files : 50</span>
          </div>
        </div> */}

      {/* <CToaster ref={toaster} push={toast} placement="top-end" /> */}

      {isCreateCampaignModal && (
        <CreateCampaignModal
          isCreateCampaignModal={isCreateCampaignModal}
          setIsCreateCampaignModal={setIsCreateCampaignModal}
          projectId={projectId}
          setProjectId={setProjectId}
        />
      )}

      {isSharedWithCampaignModal && (
        <SharedWithCampaignModal
          isSharedWithCampaignModal={isSharedWithCampaignModal}
          setIsSharedWithCampaignModal={setIsSharedWithCampaignModal}
          projectData={selectedSharedWithProject}
          handleProjectShareDelete={handleProjectShareDelete}
          allProjectData={allProjectData}
        />
      )}

      <DeleteModal
        visibleDeleteModal={visibleDeleteModal}
        setVisibleDeleteModal={setVisibleDeleteModal}
        submitDelete={submitDelete}
      />

      {isCampaignShareModal && (
        <SHareCampaignModal
          isCampaignShareModal={isCampaignShareModal}
          setIsCampaignShareModal={setIsCampaignShareModal}
          sharedProject={sharedProject}
          updateSharedUserData={updateSharedUserData}
        />
      )}
    </>
  );
};

export default CampaignManagementTable;
