import {
  CButton,
  CCard,
  CCardBody,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect } from "react";

const SharedWithCampaignModal = (props) => {
  const [selectedProject, setSelectedProject] = React.useState(null);
  const {
    isSharedWithCampaignModal,
    setIsSharedWithCampaignModal,
    projectData,
    handleProjectShareDelete,
    allProjectData,
  } = props;
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (allProjectData.data) {
      const selectedProject = allProjectData.data.find((item) => item._id === projectData._id);
      setSelectedProject(selectedProject);
    }
  }, [allProjectData]);

  return (
    <CModal
      backdrop="static"
      size="lg"
      alignment="center"
      visible={isSharedWithCampaignModal}
      onClose={() => {
        setIsSharedWithCampaignModal(false);
      }}>
      <CModalHeader className="border-0 px-4">
        <CModalTitle>Shared With {selectedProject?.name}</CModalTitle>
      </CModalHeader>
      <CModalBody className="px-4 py-0">
        <CRow>
          <CTable
            className="table table-striped table-bordered table-sm main-data-table"
            responsive>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>Name</CTableHeaderCell>
                <CTableHeaderCell>Email</CTableHeaderCell>
                <CTableHeaderCell>Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {selectedProject &&
                selectedProject?.share?.length > 0 &&
                selectedProject.share.map((item, index) => (
                  <CTableRow v-for="item in tableItems" key={index}>
                    <CTableDataCell>
                      <div>
                        {item.sharedTo.firstName} {item.sharedTo.lastName}
                      </div>
                    </CTableDataCell>
                    <CTableDataCell>{item.sharedTo.email}</CTableDataCell>

                    <CTableDataCell>
                      <CButton
                        type="button"
                        onClick={(e) => {
                          handleProjectShareDelete(item);
                        }}
                        color="primary">
                        Cancel Share
                      </CButton>
                    </CTableDataCell>
                  </CTableRow>
                ))}

              {selectedProject && selectedProject?.share?.length <= 0 && (
                <CTableRow>
                  <CTableDataCell colSpan="3" className="text-center">
                    No Data Found
                  </CTableDataCell>
                </CTableRow>
              )}
            </CTableBody>
          </CTable>
        </CRow>
        {/* <CRow className="flex-center">
          {users && users.data && users.data.length > 0 && (
            <CustomPagination
              currentPage={page || 1}
              totalPages={Math.ceil(users?.count / 5) || 1}
              onPageChange={handlePageChange}
            />
          )}
        </CRow> */}
      </CModalBody>
      <CModalFooter className="border-0 px-4 flex-end">
        <CButton
          type="button"
          color="secondary"
          className="text-white"
          onClick={() => {
            setIsSharedWithCampaignModal(false);
          }}>
          Close
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default SharedWithCampaignModal;
