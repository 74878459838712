import ApiClient from "./interceptor";

const createFiles = async (input) => {
  return ApiClient.post("/files", input);
};

const deleteFiles = async (id) => {
  return ApiClient.delete("/files/" + id);
};

const getAllFiles = async () => {
  return ApiClient.get("/files");
};

const getAllFilesByProjectId = async ({ id, params }) => {
  return ApiClient.get("/files/project/" + id, { params });
};

const getFilesById = async (id) => {
  return ApiClient.get("/files/" + id);
};

const updateFiles = async ({ id, data }) => {
  return ApiClient.patch("/files/" + id, data);
};

export { createFiles, deleteFiles, getAllFiles, getAllFilesByProjectId, getFilesById, updateFiles };
