import {
  CButton,
  CCol,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { FilterTypesHeader } from "../helpers/commonHelper";
import Select from "react-select";
import { cilDelete } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { handleFilterDataRange } from "../helpers/validateDataRangeHelper";
import toast from "react-hot-toast";
import { handleMultiCodeLogic } from "../helpers/handleMultiCodeLogic";

const MultiCodeLogicModal = (props) => {
  const {
    visibleFiltersModal,
    setVisibleFiltersModal,
    filterTypes,
    setFilteredRow,
    filteredRow,
    rows,
    columns,
    selectedColumns,
    setSelectedColumns,
    hiddenData,
    setHiddenData,
    setVisibleRow,
    handleClearFilter,
    handleFilterTypesSubHeader,
    setShowDialog,
  } = props;

  const [options, setOptions] = useState([]);
  const [rowsArray, setRowsArray] = useState([0]);

  useEffect(() => {
    const _options = columns.map((item, index) => {
      return {
        label: item,
        value: index,
      };
    });
    setOptions(_options);
  }, [columns]);

  const handleFilter = () => {
    const id = toast.loading("Applying filter...");
    const { result, _hiddenData } = handleMultiCodeLogic(
      columns,
      rows,
      selectedColumns,
      hiddenData,
      setHiddenData,
      filterTypes,
    );

    toast.success("Filter applied successfully", { id });
    setFilteredRow(result);
    handleFilterTypesSubHeader(filterTypes, _hiddenData[filterTypes]);
    setVisibleFiltersModal({});
    setShowDialog(true);
  };

  useEffect(() => {
    if (selectedColumns[filterTypes].length > 0) {
      const length = selectedColumns[filterTypes].length;
      const _rowsArray = [];
      for (let i = 0; i < length; i++) {
        _rowsArray.push(i);
      }
      setRowsArray(_rowsArray);
    }
  }, []);

  const handleAddRow = () => {
    setRowsArray([...rowsArray, rowsArray[rowsArray.length - 1] + 1]);
  };
  const handleRemoveRow = (rowIndex) => {
    let updatedRows = [];
    for (let i = 0; i < rowsArray.length - 1; i++) {
      updatedRows.push(i);
    }
    setRowsArray(updatedRows);
    const _selectedColumn = { ...selectedColumns };
    const newSelectedColumn = _selectedColumn[filterTypes].filter((_, index) => index !== rowIndex);
    _selectedColumn[filterTypes] = newSelectedColumn;

    setSelectedColumns(_selectedColumn);
  };
  const handleColumnSelect = (type, val, index) => {
    const _selectedColumns = { ...selectedColumns };
    if (_selectedColumns[filterTypes][index]) {
      _selectedColumns[filterTypes][index] = {
        ..._selectedColumns[filterTypes][index],
        [type]: val,
      };
    } else {
      _selectedColumns[filterTypes][index] = {
        [type]: val,
      };
    }

    setSelectedColumns(_selectedColumns);
  };

  return (
    <div>
      <CModal
        backdrop="static"
        size="xl"
        alignment="center"
        visible={visibleFiltersModal.multiCodeLogic}
        onClose={() => setVisibleFiltersModal({})}>
        <CModalHeader className="border-0 px-4">
          <CModalTitle>{FilterTypesHeader(filterTypes)}</CModalTitle>
        </CModalHeader>
        <CModalBody className="px-4 py-0">
          {rowsArray.map((rowIndex) => (
            <CRow key={rowIndex}>
              <CCol className="common-modal-search-box" xs={7}>
                <div>
                  <CFormLabel htmlFor={`column${rowIndex}`} className="col-sm-3 col-form-label">
                    Select Column
                  </CFormLabel>
                  <Select
                    value={
                      selectedColumns[filterTypes][rowIndex]
                        ? options[selectedColumns[filterTypes][rowIndex].column]
                        : null
                    }
                    onChange={(e) => handleColumnSelect("column", e.value, rowIndex)}
                    id={`column${rowIndex}`}
                    options={options}
                  />
                </div>
              </CCol>
              <CCol>
                <CFormLabel htmlFor={`min${rowIndex}`} className="col-sm-3 col-form-label">
                  Max Length
                </CFormLabel>
                <CFormInput
                  value={
                    selectedColumns[filterTypes][rowIndex]
                      ? selectedColumns[filterTypes][rowIndex].length
                      : 0
                  }
                  onChange={(e) => handleColumnSelect("length", e.target.value, rowIndex)}
                  id={`min${rowIndex}`}
                  type="number"
                />
              </CCol>

              {rowsArray.length > 1 && (
                <CCol xs={1} className="flex-center" style={{ marginTop: "1rem" }}>
                  <CIcon
                    onClick={() => handleRemoveRow(rowIndex)}
                    size="xl"
                    style={{ color: "red", cursor: "pointer" }}
                    icon={cilDelete}
                  />
                </CCol>
              )}
            </CRow>
          ))}
          <div className="mt-4">
            <button className="btn-custom w-100" onClick={handleAddRow}>
              Add Another Field
            </button>
            {/* <button
              onClick={() => handleRemoveRow(rowsArray.length - 1)}
              disabled={rowsArray.length === 1}
              className="btn btn-danger w-100 mt-2">
              Delete last field
            </button> */}
          </div>
        </CModalBody>
        <CModalFooter className="border-0 px-4 flex-end">
          <CButton type="button" onClick={handleFilter} color="primary">
            Apply Filter
          </CButton>
          <CButton className="text-white" type="button" onClick={handleClearFilter} color="danger">
            Clear Filter
          </CButton>
          <CButton
            className="text-white"
            type="button"
            color="secondary"
            onClick={() => setVisibleFiltersModal({})}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default MultiCodeLogicModal;
