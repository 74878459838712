import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormCheck,
  CFormTextarea,
} from "@coreui/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";

import { getAllUserTypeList } from "src/services/user-type.services";
// import { uploadImage } from "src/services/util.services";
import { useParams } from "react-router-dom";

const schema = z.object({
  userType: z.string().nonempty("Please select user type"),
  designation: z.string().min(1, "Please type Title"),
  image: z.any(),
  firstName: z.string().min(1, "Please type first name"),
  lastName: z.string().min(1, "Please type last name"),
  employeeCode: z.string().min(1, "Please type employee code"),
  email: z.string().min(1, "Please type email").email("This is not a valid email."),
});
// .refine(
//   (data) => {
//     if (data && !data.image[0]) {
//       return false;
//     }
//     return true;
//   },
//   { message: "Please select an image", path: ["image"] }
// );

const CreateCampaignForm = () => {
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState(null);
  const [userTypeData, setUserTypeData] = useState(null);
  const { id } = useParams();

  // useEffect(() => {
  //   getUserTypeInfo();
  // }, []);

  // useEffect(() => {
  //   if (id) {
  //     setUserId(id);
  //     getUserInfo(id);
  //   }
  //   console.log("id======", id);
  // }, [id]);

  // const getUserInfo = (id) => {
  //   // setIsLoading(true)
  //   getSingleUser(id)
  //     .then(async (response) => {
  //       setUserData(response.data);
  //       console.log(response.data);
  //       const { userType, designation, email, firstName, lastName, employeeCode, image } =
  //         response.data;
  //       setValue("userType", userType.type);
  //       setValue("designation", designation);
  //       setValue("email", email);
  //       setValue("firstName", firstName);
  //       setValue("lastName", lastName);
  //       setValue("employeeCode", employeeCode);
  //       setValue("image", image);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const getUserTypeInfo = () => {
    // setIsLoading(true)
    getAllUserTypeList()
      .then(async (response) => {
        const userType = response.data;
        let userTypeOptions = [{ label: "Please Select a user type", value: "" }];
        userType.forEach((element) => {
          const option = { label: element.name, value: element._id };
          userTypeOptions.push(option);
        });
        setUserTypeData(userTypeOptions);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userType: "",
      designation: "",
      email: "",
      firstName: "",
      lastName: "",
      employeeCode: "",
      image: "",
    },
    resolver: zodResolver(schema),
  });

  const [selecteduserType, setSelecteduserType] = useState("");
  const [defaultErrorMessage, setDefaultErrorMessage] = useState(
    '"Something went wrong. Please try again"',
  );

  const onSubmit = (data) => {};

  const onError = (err) => {
    console.error(err);
  };

  const handleFileUpload = async (event) => {
    // const file = event.target.files[0];
    // toast
    //   .promise(
    //     uploadImage(file),
    //     {
    //       loading: "Loading",
    //       success: "Image added Succesfully",
    //       error: (err) => {
    //         console.log(err);
    //         return "Something went wrong. Please upload again";
    //       },
    //     },
    //     {
    //       id: "login",
    //     }
    //   )
    //   .then(async (response) => {
    //     console.log("response", response);
    //     setValue("image",response)
    //   })
    //   .catch((error) => console.error(error));
  };
  return (
    <CCard className="mb-4">
      <CCardBody>
        <CRow>
          <CCol sm={5}>
            <h4 id="traffic" className="card-title mb-0">
              {userId ? "Edit Campaign" : "Create Campaign"}
            </h4>
          </CCol>
          <CCol sm={7} className="d-none d-md-block"></CCol>
        </CRow>
        <hr />
        <br />
        <CForm onSubmit={handleSubmit(onSubmit, onError)}>
          <CRow>
            <CCol sm={6}>
              <CRow className="mb-3">
                <CFormLabel htmlFor="designation" className="col-sm-3 col-form-label">
                  Title:
                </CFormLabel>
                <CCol sm={8}>
                  <CFormInput type="text" id="designation" {...register("designation")} />
                  {errors.designation?.message && (
                    <p className="error_text">{errors.designation?.message}</p>
                  )}
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CFormLabel htmlFor="remarks" className="col-sm-3 col-form-label">
                  Description:
                </CFormLabel>
                <CCol sm={8}>
                  <CFormTextarea
                    id="exampleFormControlTextarea1"
                    rows={3}
                    {...register("remarks")}
                    // text="Must be 8-20 words long."
                  ></CFormTextarea>
                  {errors.remarks?.message && (
                    <p className="error_text">{errors.remarks?.message}</p>
                  )}
                </CCol>
              </CRow>
            </CCol>
            <CCol sm={6}>
              <CRow className="mb-3">
                <CFormLabel htmlFor="image" className="col-sm-3 col-form-label">
                  Image:
                </CFormLabel>
                <CCol sm={8}>
                  <CFormInput
                    type="file"
                    accept="image/*"
                    onChange={handleFileUpload}
                    // id="image"
                    // {...register("image")}
                  />
                  {errors.image?.message && <p className="error_text">{errors.image?.message}</p>}
                </CCol>
              </CRow>
            </CCol>

            <CRow className="mt-5">
              <CCol sm={1}></CCol>
              <CCol sm={10}>
                <div className="d-grid gap-6">
                  <CButton type="submit" color="primary">
                    Submit
                  </CButton>
                </div>
              </CCol>
              <CCol sm={1}></CCol>
            </CRow>
          </CRow>
        </CForm>
      </CCardBody>
    </CCard>
  );
};

export default CreateCampaignForm;
