import { FilterTypes } from "../commontypes";
import { removeHiddenRows } from "./commonHelper";

const handleAssesResponsePattern = (
  columns,
  rows,
  selectedColumns,
  hiddenData,
  setHiddenData,
  filterTypes,
  flatLinersPercentageValue,
) => {
  const _hiddenData = { ...hiddenData };
  const _selectedColumns = selectedColumns[filterTypes];
  let result = rows.filter((row) => {
    let uniqueVal;
    let duplicateCount = 0;
    for (let i = 0; i < _selectedColumns.length; i++) {
      const column = columns[_selectedColumns[i]];
      if (!uniqueVal) {
        uniqueVal = row[column];
      }
      if (uniqueVal === row[column]) {
        duplicateCount++;
      }
      if (uniqueVal !== row[column]) {
        uniqueVal = row[column];
      }
    }
    const length = _selectedColumns.length;
    const percentage = (duplicateCount / length) * 100;
    if (percentage >= Number(flatLinersPercentageValue)) {
      return true;
    }
  });
  _hiddenData[FilterTypes.ASSES_RESPONSE_PATTERNS] = [
    ..._hiddenData[FilterTypes.ASSES_RESPONSE_PATTERNS],
    ...result,
  ];
  setHiddenData(_hiddenData);
  result = removeHiddenRows(rows, _hiddenData);
  return { _hiddenData, result };
};

export { handleAssesResponsePattern };
