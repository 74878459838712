import axios from "axios";
import ApiClient from "./interceptor";
import { API_URL } from "../enviroment/enviroment";

const getAuthToken = () => {
  const authToken = localStorage.getItem("auth");
  if (authToken && authToken !== "undefined") {
    return JSON.parse(authToken);
  } else {
    return null;
  }
  // if (typeof window === "undefined") return null;
  // else return JSON.parse(localStorage.getItem("auth"));
};

const emailVerification = async (input) => {
  return axios
    .post(`${API_URL}/auth/login`, input, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((result) => {
      return result.data;
    });
};

const tokenVerification = async (input) => {
  return axios
    .post(`${API_URL}/auth/token`, input, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((result) => {
      return result.data;
    });
};

const signIn = async (input) => {
  return ApiClient.post("/auth/login", input);
};

export { getAuthToken, emailVerification, tokenVerification, signIn };
