import axios from "axios";
import { REST_ENDPOINT } from "../enviroment/enviroment";
import { stringify, parse } from "qs";
import toast from "react-hot-toast";
const ApiClient = axios.create({
  baseURL: REST_ENDPOINT,
  paramsSerializer: {
    encode: parse,
    serialize: stringify, // or (params) => Qs.stringify(params, {arrayFormat: 'brackets'})
  },
});
ApiClient.interceptors.request.use(
  async (config) => {
    const authToken = JSON.parse(localStorage.getItem("auth"));
    if (authToken) {
      config.headers = {
        Authorization: authToken,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

ApiClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      toast.error("Session expired, please login again");
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);

export default ApiClient;
