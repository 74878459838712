import {
  CButton,
  CCol,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FilterTypesHeader } from "../helpers/commonHelper";
import Select from "react-select";
import CIcon from "@coreui/icons-react";
import { cilDelete } from "@coreui/icons";
import { handleCrossTabulation } from "../helpers/handleCrossTabulationHelper";

const CrossTabulationModal = (props) => {
  const {
    visibleFiltersModal,
    setVisibleFiltersModal,
    filterTypes,
    setFilteredRow,
    filteredRow,
    rows,
    columns,
    selectedColumns,
    setSelectedColumns,
    hiddenData,
    setHiddenData,
    setVisibleRow,
    handleClearFilter,
    handleFilterTypesSubHeader,
    setShowDialog,
  } = props;

  const [options, setOptions] = useState([]);
  const [rowsArray, setRowsArray] = useState([0]);

  useEffect(() => {
    const _options = columns.map((item, index) => {
      return {
        label: item,
        value: index,
      };
    });
    setOptions(_options);
  }, [columns]);

  const handleFilter = () => {
    const id = toast.loading("Applying filter...");
    const { result, _hiddenData } = handleCrossTabulation(
      columns,
      rows,
      selectedColumns,
      hiddenData,
      setHiddenData,
      filterTypes,
    );

    toast.success("Filter applied successfully", { id });
    setFilteredRow(result);
    handleFilterTypesSubHeader(filterTypes, _hiddenData[filterTypes]);
    setVisibleFiltersModal({});
    setShowDialog(true);
  };

  useEffect(() => {
    if (selectedColumns[filterTypes].length > 0) {
      const length = selectedColumns[filterTypes].length;
      const _rowsArray = [];
      for (let i = 0; i < length; i++) {
        _rowsArray.push(i);
      }
      setRowsArray(_rowsArray);
    }
  }, []);

  const handleAddRow = () => {
    setRowsArray([...rowsArray, rowsArray[rowsArray.length - 1] + 1]);
  };
  const handleRemoveRow = (rowIndex) => {
    let updatedRows = [];
    for (let i = 0; i < rowsArray.length - 1; i++) {
      updatedRows.push(i);
    }
    setRowsArray(updatedRows);
    const _selectedColumn = { ...selectedColumns };
    const newSelectedColumn = _selectedColumn[filterTypes].filter((_, index) => index !== rowIndex);
    _selectedColumn[filterTypes] = newSelectedColumn;

    setSelectedColumns(_selectedColumn);
  };
  const handleColumnSelect = (type, val, index) => {
    const _selectedColumns = { ...selectedColumns };
    if (_selectedColumns[filterTypes][index]) {
      _selectedColumns[filterTypes][index] = {
        ..._selectedColumns[filterTypes][index],
        [type]: val,
      };
    } else {
      _selectedColumns[filterTypes][index] = {
        [type]: val,
      };
    }
    setSelectedColumns(_selectedColumns);
  };

  return (
    <div>
      <CModal
        backdrop="static"
        size="xl"
        alignment="center"
        visible={visibleFiltersModal.crossTabulation}
        onClose={() => setVisibleFiltersModal({})}>
        <CModalHeader className="border-0 px-4">
          <CModalTitle>{FilterTypesHeader(filterTypes)}</CModalTitle>
        </CModalHeader>
        <CModalBody className="px-4 py-0">
          {rowsArray.map((rowIndex) => (
            <div className="mb-4" key={rowIndex}>
              <div className="d-flex gap-3">
                <div className="w-50 common-modal-search-box">
                  <CFormLabel htmlFor={`source${rowIndex}`} className="col-5 col-form-label">
                    Source Column
                  </CFormLabel>
                  <Select
                    value={
                      selectedColumns[filterTypes][rowIndex]
                        ? options[selectedColumns[filterTypes][rowIndex].source]
                        : null
                    }
                    onChange={(e) => handleColumnSelect("source", e.value, rowIndex)}
                    id={`source${rowIndex}`}
                    options={options}
                  />
                </div>
                <div className="w-50">
                  <CFormLabel htmlFor={`target${rowIndex}`} className="col-5 col-form-label">
                    Target Column
                  </CFormLabel>
                  <Select
                    value={
                      selectedColumns[filterTypes][rowIndex]
                        ? options[selectedColumns[filterTypes][rowIndex].target]
                        : null
                    }
                    onChange={(e) => handleColumnSelect("target", e.value, rowIndex)}
                    id={`target${rowIndex}`}
                    options={options}
                  />
                </div>

                {rowsArray.length > 1 && (
                  <div className="flex-center" style={{ marginTop: "1rem" }}>
                    <CIcon
                      onClick={() => handleRemoveRow(rowIndex)}
                      size="xl"
                      style={{ color: "red", cursor: "pointer" }}
                      icon={cilDelete}
                    />
                  </div>
                )}
              </div>
              <div className="d-flex align-items-end gap-3">
                <CCol>
                  <CFormLabel htmlFor={`min${rowIndex}`} className=" col-form-label">
                    Source Column Min Range
                  </CFormLabel>
                  <CFormInput
                    value={
                      selectedColumns[filterTypes][rowIndex]
                        ? selectedColumns[filterTypes][rowIndex].min
                        : null
                    }
                    onChange={(e) => handleColumnSelect("min", e.target.value, rowIndex)}
                    id={`min${rowIndex}`}
                    type="number"
                  />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor={`max${rowIndex}`} className=" col-form-label">
                    Source Column Max Range
                  </CFormLabel>
                  <CFormInput
                    value={
                      selectedColumns[filterTypes][rowIndex]
                        ? selectedColumns[filterTypes][rowIndex].max
                        : null
                    }
                    onChange={(e) => handleColumnSelect("max", e.target.value, rowIndex)}
                    id={`max${rowIndex}`}
                    type="number"
                  />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor={`expected${rowIndex}`} className=" col-form-label">
                    Include(Comma Separated)
                  </CFormLabel>
                  <CFormInput
                    value={
                      selectedColumns[filterTypes][rowIndex]
                        ? selectedColumns[filterTypes][rowIndex].expected
                        : null
                    }
                    onChange={(e) => handleColumnSelect("expected", e.target.value, rowIndex)}
                    id={`expected${rowIndex}`}
                    type="text"
                  />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor={`unexpected${rowIndex}`} className="col-form-label">
                    Exclude(Comma Separated)
                  </CFormLabel>
                  <CFormInput
                    value={
                      selectedColumns[filterTypes][rowIndex]
                        ? selectedColumns[filterTypes][rowIndex].unexpected
                        : null
                    }
                    onChange={(e) => handleColumnSelect("unexpected", e.target.value, rowIndex)}
                    id={`unexpected${rowIndex}`}
                    type="text"
                  />
                </CCol>
              </div>
            </div>
          ))}
          <div className="mt-4">
            <button className="btn-custom w-100" onClick={handleAddRow}>
              Add Another Field
            </button>
            {/* <button
                  onClick={() => handleRemoveRow(rowsArray.length - 1)}
                  disabled={rowsArray.length === 1}
                  className="btn btn-danger w-100 mt-2">
                  Delete last field
                </button> */}
          </div>
        </CModalBody>
        <CModalFooter className="border-0 px-4 flex-end">
          <CButton type="button" onClick={handleFilter} color="primary">
            Apply Filter
          </CButton>
          <CButton className="text-white" type="button" onClick={handleClearFilter} color="danger">
            Clear Filter
          </CButton>
          <CButton
            className="text-white"
            type="button"
            color="secondary"
            onClick={() => setVisibleFiltersModal({})}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default CrossTabulationModal;
