import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormCheck,
} from "@coreui/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { getAllUserTypeList } from "src/services/user-type.services";
// import { uploadImage } from "src/services/util.services";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createUser, getUserById, updateUser } from "src/services/user.services";

const schema = z
  .object({
    userType: z.string().nonempty("Please select user type"),
    firstName: z.string().min(1, "Please type first name"),
    lastName: z.string().min(1, "Please type last name"),
    email: z.string().min(1, "Please type email").email("This is not a valid email."),
    password: z.string().min(1, "Password is required"),
    confirmPassword: z.string().min(1, "Password confirmation is required"),
    permission: z.object({
      addProject: z.boolean(),
      editProject: z.boolean(),
      deleteProject: z.boolean(),
      uploadFile: z.boolean(),
      addFile: z.boolean(),
      editFile: z.boolean(),
      deleteFile: z.boolean(),
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "Passwords do not match",
  });

const CreateUserForm = () => {
  const [userId, setUserId] = useState("");
  const { id } = useParams();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userType: "",
      password: "",
      confirmPassword: "",
      email: "",
      firstName: "",
      lastName: "",
      permission: {
        addProject: false,
        editProject: false,
        deleteProject: false,
        uploadFile: false,
        addFile: false,
        editFile: false,
        deleteFile: false,
      },
    },
    resolver: zodResolver(schema),
  });

  const { isLoading, mutate } = useMutation(createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      toast.dismiss();
      toast.success("User created successfully");
      navigate("/manage-user");
    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err.message || "Something went wrong");
    },
  });

  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
  } = useQuery(["users", id], () => getUserById(id), {
    staleTime: 1000 * 60 * 5,
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUser } = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      toast.dismiss();
      toast.success("User updated successfully");
      navigate("/manage-user");
    },
    onError: (err) => {
      toast.dismiss();
      toast.error("Something went wrong", err.message);
    },
  });

  const onSubmit = (data) => {
    if (id) {
      mutateUpdateUser({ id, data });
      return;
    }
    mutate(data);
  };

  if (isLoadingUpdateUser && id) {
    toast.dismiss();
    toast.loading("Updating user...");
  }
  if (isUserDataLoading && id) {
    toast.dismiss();
    toast.loading("Loading user data...");
  }

  if (isUserDataError) {
    toast.dismiss();
    toast.error("Something went wrong");
  }

  if (isLoading) {
    toast.dismiss();
    toast.loading("Creating user...");
  }

  useEffect(() => {
    if (userData && !isLoading && !isUserDataLoading) {
      toast.dismiss();
      setValue("userType", userData.data.userType);
      setValue("firstName", userData.data.firstName);
      setValue("lastName", userData.data.lastName);
      setValue("email", userData.data.email);
      setValue("permission", userData.data.permission);
    }
  }, [userData, isLoading, isUserDataLoading, setValue]);

  const onError = (err) => {
    console.error(err);
  };

  return (
    <CCard className="user-managment-card rounded-0 border-0 mb-4 p-3">
      <CCardBody>
        <CRow>
          <CCol sm={5}>
            <h4 id="traffic" className="card-title mb-0">
              {id ? "Edit User" : "Create User"}
            </h4>
          </CCol>
          <CCol sm={7} className="d-none d-md-block"></CCol>
        </CRow>
        <br />
        <CForm onSubmit={handleSubmit(onSubmit, onError)}>
          <CRow>
            <CCol sm={6}>
              <div className="mb-2">
                <CFormLabel htmlFor="userType" className="text-nowrap col-lg-2 col-form-label">
                  User Type:
                </CFormLabel>
                <CFormSelect
                  {...register("userType")}
                  defaultValue="user"
                  disabled={id ? true : false}
                  onChange={(e) =>
                    setValue("userType", e.target.value, {
                      shouldValidate: true,
                    })
                  }>
                  <option value="user">User</option>
                  <option value="superadmin">Admin</option>
                </CFormSelect>
                {errors.userType?.message && (
                  <p className="error_text">{errors.userType?.message}</p>
                )}
              </div>
              <div className="mb-2">
                <CFormLabel htmlFor="firstName" className="text-nowrap col-lg-2 col-form-label">
                  First Name:
                </CFormLabel>
                <CFormInput type="text" id="firstName" {...register("firstName")} />
                {errors.firstName?.message && (
                  <p className="error_text">{errors.firstName?.message}</p>
                )}
              </div>
              <div className="mb-2">
                <CFormLabel htmlFor="lastName" className="text-nowrap col-lg-2 col-form-label">
                  Last Name:
                </CFormLabel>
                <CFormInput type="text" id="lastName" {...register("lastName")} />
                {errors.lastName?.message && (
                  <p className="error_text">{errors.lastName?.message}</p>
                )}
              </div>
            </CCol>
            <CCol sm={6}>
              <div className="mb-2">
                <CFormLabel htmlFor="email" className="text-nowrap col-lg-2 col-form-label">
                  Email:
                </CFormLabel>
                <CFormInput
                  disabled={id ? true : false}
                  type="email"
                  id="email"
                  {...register("email")}
                />
                {errors.email?.message && <p className="error_text">{errors.email?.message}</p>}
              </div>
              <div className="mb-2">
                <CFormLabel htmlFor="password" className="text-nowrap col-lg-2 col-form-label">
                  Password:
                </CFormLabel>
                <CFormInput type="password" id="password" {...register("password")} />
                {errors.password?.message && (
                  <p className="error_text">{errors.password?.message}</p>
                )}
              </div>

              <div className="mb-2">
                <CFormLabel
                  htmlFor="confirmPassword"
                  className="text-nowrap col-lg-2 col-form-label">
                  Confirm Password
                </CFormLabel>
                <CFormInput type="password" id="confirmPassword" {...register("confirmPassword")} />
                {errors.confirmPassword?.message && (
                  <p className="error_text">{errors.confirmPassword?.message}</p>
                )}
              </div>
            </CCol>
            <CCol sm={12}>
              <h5 className="text-nowrap col-lg-1 col-form-label">Permission:</h5>
              <div className="d-flex align-items-center gap-3 px-4 py-3 rounded-3 bg-gray-50 mb-4">
                <div className="d-flex gap-3 create-user-permissions">
                  <CFormCheck
                    className="p-3"
                    id="addProject"
                    label="Add Project"
                    {...register("permission.addProject")}
                  />
                  <CFormCheck
                    className="p-3"
                    id="editProject"
                    label="Edit Project"
                    {...register("permission.editProject")}
                  />
                  <CFormCheck
                    className="p-3"
                    id="deleteProject"
                    label="Delete Project"
                    {...register("permission.deleteProject")}
                  />
                  <CFormCheck
                    className="p-3"
                    id="uploadFile"
                    label="Upload File"
                    {...register("permission.uploadFile")}
                  />
                  <CFormCheck
                    className="p-3"
                    id="addFile"
                    label="Add File"
                    {...register("permission.addFile")}
                  />
                  <CFormCheck
                    className="p-3"
                    id="editFile"
                    label="Edit File"
                    {...register("permission.editFile")}
                  />
                  <CFormCheck
                    className="p-3"
                    id="deleteFile"
                    label="Delete File"
                    {...register("permission.deleteFile")}
                  />
                </div>
              </div>
            </CCol>
            <CCol sm={12}>
              <hr className="border-top" />
            </CCol>
            <CCol sm={12} className="text-end">
              <CButton type="submit" color="primary">
                Submit
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </CCardBody>
    </CCard>
  );
};

export default CreateUserForm;
