import { CPagination, CPaginationItem } from "@coreui/react";

const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <CPaginationItem
          key={i}
          onClick={() => handlePageChange(i)}
          className={i === currentPage ? "active" : ""}
        >
          {i}
        </CPaginationItem>,
      );
    }
    return pageNumbers;
  };

  return (
    <div style={{ marginRight: 7 }}>
      <CPagination align="end" aria-label="Page navigation example">
        <CPaginationItem
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Prev
        </CPaginationItem>

        {renderPageNumbers()}
        <CPaginationItem
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </CPaginationItem>
      </CPagination>
    </div>
  );
};

export default CustomPagination;

// const CustomPagination = (props) => {
//   return (
//     <div style={{marginRight: 7}}>
//       <CPagination align="end" aria-label="Page navigation example">
//         <CPaginationItem disabled>Previous</CPaginationItem>
//         <CPaginationItem>1</CPaginationItem>
//         <CPaginationItem>2</CPaginationItem>
//         <CPaginationItem>3</CPaginationItem>
//         <CPaginationItem>Next</CPaginationItem>
//       </CPagination>
//     </div>
//   )
// }
// export default CustomPagination;
