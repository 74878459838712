import * as _model from "./model.json";
const assignScore = (test, matrix, useCache = true) => {
  // Replace line breaks with spaces
  test = String(test).split("\n").join(" ");
  let modelCache = {};

  let split = test.toLowerCase().split("");
  let pairCount = 0;
  let totalScore = 0;

  for (let x = 0; x < split.length; x++) {
    // don't do anything if the letter is by itself (last letter of the sample)
    if (!split[x + 1]) {
      break;
    }

    let modelFind;
    let letterPair = String(split[x]) + String(split[x + 1]);

    if (useCache) {
      if (modelCache[letterPair]) {
        modelFind = modelCache[letterPair];
      } else {
        modelFind = matrix.find((m) => m.x == letterPair);
        modelCache[letterPair] = modelFind;
      }
    } else {
      modelFind = modelFind = matrix.find((m) => m.x == letterPair);
    }

    pairCount++;

    // if match was found, add it to total score count
    if (modelFind) {
      totalScore += modelFind.y;
    }
  }

  // return average
  return totalScore / pairCount;
};
const calculateThreshold = (model) => (model.baseline.good.min + model.baseline.bad.max) / 2;

const scoreLines = (lines, model) => {
  let runningScore = 0;
  let minimum;
  let maximum;
  for (let x = 0; x < lines.length; x++) {
    lines[x] = String(lines[x]).trim();
    let score = assignScore(lines[x], model);

    runningScore += score;

    if (!minimum || score < minimum) {
      minimum = score;
    }
    if (!maximum || score > maximum) {
      maximum = score;
    }
  }

  return { min: minimum, max: maximum, avg: runningScore / lines.length };
};

const testGibberish = (test, model = _model, thresholdFn = calculateThreshold, useCache = true) => {
  thresholdFn = thresholdFn || calculateThreshold;
  return assignScore(test, model.matrix, useCache) < thresholdFn(model);
};

export { testGibberish };
