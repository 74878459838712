import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilSpeedometer,
  cilPeople,
  cilDescription,
  cilMonitor,
  cilSettings,
  cilMap,
  cilNoteAdd,
  cilChatBubble,
  cilWarning,
} from "@coreui/icons";
import { CNavGroup, CNavItem } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    menu: "dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: "info",
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: "Projects",
    menu: "projects",
    to: "/projects",
    icon: <CIcon icon={cilMonitor} customClassName="nav-icon" />,
    badge: {
      color: "info",
      // text: 'NEW',
    },
  },

  {
    component: CNavGroup,
    name: "Manage User settings",
    menu: "manage_user_settings",
    to: "/manage-user-type",
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    badge: {
      color: "info",
      // text: 'NEW',
    },
    items: [
      // {
      //   component: CNavItem,
      //   name: "Manage User Type",
      //   menu: "manage_user_type",
      //   to: "/manage-user-type",
      //   icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
      //   badge: {
      //     color: "info",
      //     // text: 'NEW',
      //   },
      // },
      {
        component: CNavItem,
        name: "Manage User",
        menu: "manage_user",
        to: "/manage-user",
        icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
        badge: {
          color: "info",
          // text: 'NEW',
        },
      },
    ],
  },

  {
    component: CNavItem,
    name: "Profile",
    menu: "settings",
    to: "/profile",
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    badge: {
      // color: 'info',
      // text: 'NEW',
    },
  },
];

export default _nav;
