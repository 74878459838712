import React, { useRef, useState, useEffect } from "react";
import { getUserList } from "../../services/user.services";
import toast from "react-hot-toast";
import {
  CAvatar,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CToaster,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilPeople, cilPencil, cilPlus } from "@coreui/icons";
import CustomPagination from "../../components/CustomPagination";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import avatar1 from "src/assets/images/avatars/1.jpg";
import avatar2 from "src/assets/images/avatars/2.jpg";
import avatar3 from "src/assets/images/avatars/3.jpg";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { FileUploader } from "react-drag-drop-files";
import ApplyFilters from "src/components/Filters/ApplyFilters";

const CampaignManagementFileUpload = () => {
  const toaster = useRef();
  const [toast, addToast] = useState("");
  const fileTypes = ["XLSX"];
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  return (
    <>
      {file ? (
        <ApplyFilters uploadedFile={file} />
      ) : (
        <CCard className="mb-4">
          <CCardBody className="campaign-card">
            <CRow>
              <CCol sm={5}>
                <h4 id="traffic" className="card-title mb-0">
                  File Upload
                </h4>
              </CCol>
              <CCol sm={7} className="d-none d-md-block">
                <div className="d-flex justify-content-end">
                  {/* <Link to="/create-campaign" style={{marginRight:"10px"}}>
                  <CButton color="success">
                    <CIcon icon={cilPlus} />
                    &nbsp; Create
                  </CButton>
                </Link>

                <Link to="/upload-file">
                  <CButton color="primary">
                    <CIcon icon={cilPlus} />
                    &nbsp; Upload File
                  </CButton>
                </Link> */}
                </div>
              </CCol>
            </CRow>
            <br />
            <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
            <br />

            {/* <CustomPagination 
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            /> */}
          </CCardBody>
        </CCard>
      )}
      <CToaster ref={toaster} push={toast} placement="top-end" />
    </>
  );
};

export default CampaignManagementFileUpload;
