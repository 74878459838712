import { cilDelete } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CTooltip, CTable, CTableBody, CTableDataCell, CTableHead, CTableRow } from "@coreui/react";
import React, { useCallback, useEffect } from "react";

const DataTable = (props) => {
  const { rows, columns, filterTypesSubHeaderSelected, handleRestore } = props;

  const handleColumnHeaderBigText = useCallback((column = "") => {
    const size = column.length;
    if (size > 40) {
      return column.slice(0, 20) + " ... " + column.slice(-20);
    }
    return column;
  }, []);

  return (
    <div className="data-table-wrapper">
      <CTable
        responsive="md"
        className="table table-striped table-bordered table-sm main-data-table"
        cellSpacing="0"
        id="dataTable">
        <CTableHead>
          <CTableRow>
            {filterTypesSubHeaderSelected !== "all" && <th>Action</th>}
            {columns?.map((column, index) => (
              <CTableDataCell key={index} scope="col">
                {
                  <CTooltip content={column}>
                    <span>{handleColumnHeaderBigText(column)}</span>
                  </CTooltip>
                }
              </CTableDataCell>
            ))}
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {rows.length > 0 &&
            rows?.map((row, index) => (
              <>
                <CTableRow scope="row" key={index}>
                  {filterTypesSubHeaderSelected !== "all" && (
                    <CTableDataCell scope="row">
                      <CIcon
                        onClick={() => handleRestore(row, index)}
                        style={{ color: "red", cursor: "pointer" }}
                        icon={cilDelete}
                      />
                    </CTableDataCell>
                  )}
                  {columns.map((column, index2) => (
                    <>
                      <CTableDataCell key={index2}>
                        {
                          <CTooltip content={row[column]}>
                            <span>{handleColumnHeaderBigText(row[column])}</span>
                          </CTooltip>
                        }
                      </CTableDataCell>
                    </>
                  ))}
                </CTableRow>
              </>
            ))}
        </CTableBody>
      </CTable>
    </div>
  );
};

export default React.memo(DataTable);
