import {
  CButton,
  CCard,
  CCardBody,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import toast from "react-hot-toast";
import { createProject, getProjectById, updateProject } from "src/services/project.services";
import { z } from "zod";
import ShortUniqueId from "short-unique-id";

const CreateCampaignModal = (props) => {
  const { isCreateCampaignModal, setIsCreateCampaignModal, projectId, setProjectId } = props;
  const [isUpdating, setIsUpdating] = React.useState(false);

  const createProjectSchema = z.object({
    name: z.string().min(1, "Name is required").max(100),
    surveyId: z.string().min(1, "Survey Id is required").max(100),
    clientName: z.string().min(1, "Client Name is required").max(100),
    Description: z.string().min(1, "Description is required").max(100),
  });

  useEffect(() => {
    if (projectId) {
      setIsUpdating(true);
    }
  }, [projectId]);

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(createProject, {
    onSuccess: () => {
      toast.dismiss();
      toast.success("Project created successfully");
      queryClient.invalidateQueries("projects");
      setIsCreateCampaignModal(false);
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message || "Something went wrong");
    },
  });

  const {
    data: projectData,
    isLoading: projectDataLoading,
    isError: projectDataError,
  } = useQuery(["projects", projectId], () => getProjectById(projectId), {
    enabled: isUpdating,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const { mutate: mutateUpdateProject, isLoading: isLoadingUpdateProject } = useMutation(
    updateProject,
    {
      onSuccess: () => {
        toast.dismiss();
        toast.success("Project updated successfully");
        queryClient.invalidateQueries("projects");
        setProjectId(null);
        setIsCreateCampaignModal(false);
      },
      onError: (error) => {
        toast.dismiss();
        toast.error(error.message || "Something went wrong");
      },
    },
  );

  if (isLoading) {
    toast.dismiss();
    toast.loading("Creating project...");
  }

  if (isUpdating && projectDataLoading) {
    toast.dismiss();
    toast.loading("Fetching project data...");
  }

  if (isUpdating && projectDataError) {
    toast.dismiss();
    toast.error("Something went wrong");
  }

  if (isLoadingUpdateProject) {
    toast.dismiss();
    toast.loading("Updating project...");
  }

  const {
    register,
    handleSubmit,
    setValue,
    isSubmitting,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(createProjectSchema),
  });

  useEffect(() => {
    if (projectData) {
      toast.dismiss();
      setValue("name", projectData.data.name);
      setValue("surveyId", projectData.data.surveyId);
      setValue("clientName", projectData.data.clientName);
      setValue("Description", projectData.data.Description);
    }
  }, [projectData, setValue]);

  const onSubmit = (data) => {
    if (isUpdating) {
      mutateUpdateProject({ id: projectId, data });
      return;
    }
    const _data = { ...data };
    const uid = new ShortUniqueId({ length: 10 });
    _data.projectIdentifier = uid();
    mutate(_data);
  };
  return (
    <CModal
      backdrop="static"
      size="lg"
      alignment="center"
      visible={isCreateCampaignModal}
      onClose={() => {
        setProjectId(null);
        setIsUpdating(false);
        setIsCreateCampaignModal(false);
      }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CModalHeader className="border-0 px-4">
          <CModalTitle>{isUpdating ? "Update Project" : "Create Project"}</CModalTitle>
        </CModalHeader>
        <CModalBody className="px-4 py-0">
          <CRow>
            <div className="form-group mb-2">
              <label htmlFor="project_name">Project Name</label>
              <input
                type="text"
                className="form-control"
                id="project_name"
                aria-describedby="Project Name"
                placeholder="Enter project name"
                {...register("name")}
              />
              {errors.name && <span className="text-danger">{errors.name.message}</span>}
            </div>

            <div className="form-group mb-2">
              <label htmlFor="survey_id">Survey Id</label>
              <input
                type="text"
                className="form-control"
                id="survey_id"
                aria-describedby="survey id"
                placeholder="Enter survey id"
                {...register("surveyId")}
              />
              {errors.surveyId && <span className="text-danger">{errors.surveyId.message}</span>}
            </div>

            <div className="form-group mb-2">
              <label htmlFor="client_name">Client Name</label>
              <input
                type="text"
                className="form-control"
                id="client_name"
                aria-describedby="client name"
                placeholder="Enter client name"
                {...register("clientName")}
              />
              {errors.clientName && (
                <span className="text-danger">{errors.clientName.message}</span>
              )}
            </div>

            <div className="form-group mb-2">
              <label htmlFor="description">Description</label>
              <input
                type="text"
                className="form-control"
                id="description"
                aria-describedby="description"
                placeholder="Enter description"
                {...register("Description")}
              />
              {errors.Description && (
                <span className="text-danger">{errors.Description.message}</span>
              )}
            </div>
          </CRow>
        </CModalBody>
        <CModalFooter className="border-0 flex-end px-4">
          <CButton disabled={isSubmitting} type="submit" color="primary">
            {isUpdating ? "Update" : "Create"}
          </CButton>
          <CButton
            disabled={isSubmitting}
            type="button"
            color="secondary"
            className="text-white"
            onClick={() => {
              setProjectId(null);
              setIsUpdating(false);
              setIsCreateCampaignModal(false);
            }}>
            Close
          </CButton>
        </CModalFooter>
      </form>
    </CModal>
  );
};

export default CreateCampaignModal;
