import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  getAuthToken,
  emailVerification,
  tokenVerification,
  signIn,
} from "../../../services/auth.services";
import { setAuthToken } from "../../../uitils/LocalStorage";
import { useNavigate, Navigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

const Login = () => {
  const [emailId, setEmailId] = useState("");
  const [stepper, setStepper] = useState(1);

  const navigate = useNavigate();
  const auth = getAuthToken();
  // console.log(auth);

  const { mutate: SignIn } = useMutation(signIn, {
    onSuccess: (data) => {
      toast.dismiss();
      setAuthToken(data);
      navigate("/dashboard", {
        replace: true,
      });
    },
    onError: (error) => {
      toast.dismiss();
      console.log(error.message);
      toast.error("Invalid Credentials");
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onEmailSubmit = async (data) => {
    let formData = new FormData();
    formData.append("email", data.email);
    formData.append("type", "otp");
    toast
      .promise(
        emailVerification(formData),
        {
          loading: "Loading",
          success: "Please check your email for verification code.",
          error: (err) => {
            console.log(err);
            return "Something went wrong.";
          },
        },
        {
          id: "login",
        },
      )
      .then(async (response) => {
        const { data } = response;
        const { email_id } = data;
        setEmailId(email_id);
        setStepper(2);
      })
      .catch((error) => console.error(error));
  };

  const onTokenSubmit = async (data) => {
    let formData = new FormData();
    formData.append("method_id", emailId);
    formData.append("code", data.token);
    toast
      .promise(
        tokenVerification(formData),
        {
          loading: "Loading",
          success: "Success",
          error: (err) => {
            console.log(err);
            return "Something went wrong.";
          },
        },
        {
          id: "login",
        },
      )
      .then(async (response) => {
        const { token } = response;
        setAuthToken(response);
        navigate("/dashboard", {
          replace: true,
        });
      })
      .catch((error) => console.error(error));
  };

  const onSubmit = async (data) => {
    const signInToast = toast.loading("Signing in...");
    SignIn(data);
  };

  return auth == null ? (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={4}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit(onSubmit)}>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        {...register("email", { required: true })}
                        placeholder="email"
                        autoComplete="email"
                      />
                    </CInputGroup>
                    {errors.email && <span className="error_text">This field is required</span>}
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        {...register("password", { required: true })}
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                      {errors.password && <span>This field is required</span>}
                    </CInputGroup>
                    <CRow className="mt-4">
                      <CCol xs={6}>
                        <CButton color="primary" className="px-4" type="submit">
                          Login
                        </CButton>
                      </CCol>
                      {/* <CCol xs={6} className="text-right">
                          <CButton color="link" className="px-0">
                            Forgot password?
                          </CButton>
                        </CCol> */}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              {/* <CCard
                  className="text-white bg-primary py-5"
                  style={{ width: "44%" }}
                >
                  <CCardBody className="text-center">
                    <div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua.
                      </p>
                    </div>
                  </CCardBody>
                </CCard> */}
            </CCardGroup>
          </CCol>

          {/* {stepper === 2 && (
            <CCol md={5}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm onSubmit={handleSubmit(onTokenSubmit)}>
                      <h1>Authentication Token</h1>
                      <p className="text-medium-emphasis">
                        Type your authentication token
                      </p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput
                          {...register("token", { required: true })}
                          placeholder="token"
                          autoComplete="token"
                        />
                      </CInputGroup>
                      {errors.token && (
                        <span className="error_text">
                          This field is required
                        </span>
                      )}
                      <CRow className="mt-4">
                        <CCol xs={6}>
                          <CButton
                            color="primary"
                            className="px-4"
                            type="submit"
                          >
                            Submit
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          )} */}
        </CRow>
      </CContainer>
    </div>
  ) : (
    <Navigate to="/dashboard" />
  );
};
export default Login;
