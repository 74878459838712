import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormCheck,
} from "@coreui/react";
import { z, object, string, boolean } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import {
  createUserType,
  getSingleUserType,
  updateUserType,
} from "../../services/user-type.services";
import { useParams } from "react-router-dom";

const schema = z.object({
  name: z.string().min(1, "Please type a name"),
  userType: z.string().min(1, "Please select user type"),
  // designation: z.string().min(1, "Please select designation"),
  // image: z.any("Required"),
  permission: object({
    manage_user_type: z.boolean().optional(),
    manage_user: z.boolean().optional(),
    manage_store: z.boolean().optional(),
    manage_plastic_seal: z.boolean().optional(),
    new_booking_orders: z.boolean().optional(),
    manage_booking_orders: z.boolean().optional(),
    create_booking_order: z.boolean().optional(),
    manage_route: z.boolean().optional(),
    manage_trip: z.boolean().optional(),
    report_issue: z.boolean().optional(),
    check_delivery_status: z.boolean().optional(),
    tranfer_package: z.boolean().optional(),
    settings: z.boolean().optional(),
  }),
});

const CreateUserTypeForm = () => {
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setUserId(id);
      getUserTypeInfo(id);
    }
  }, [id]);

  const getUserTypeInfo = (id) => {
    // setIsLoading(true)
    getSingleUserType(id)
      .then(async (response) => {
        setUserData(response.data);
        const { name, permission, type } = response.data;
        // setValue('name',response.data.name)
        setValue("name", name);
        setValue("permission", permission);
        setValue("userType", type);
        // setIsLoading(false)
      })
      .catch((error) => {
        // setIsLoading(false)
        console.error(error);
      });
  };

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userType: "",
      name: "",
      // image: "",
      permission: {
        manage_user_type: false,
        manage_user: false,
        manage_store: false,
        manage_plastic_seal: false,
        create_booking_order: false,
        new_booking_orders: false,
        manage_booking_orders: false,
        manage_route: false,
        manage_trip: false,
        report_issue: false,
        check_delivery_status: false,
        tranfer_package: false,
        settings: false,
      },
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = (data) => {
    if (userId) {
      toast
        .promise(
          updateUserType(data, userId),
          {
            loading: "Loading",
            success: "User Type Updated Succesfully",
            error: (err) => {
              console.log(err);
              return "Something went wrong.";
            },
          },
          {
            id: "login",
          },
        )
        .then(async (response) => {})
        .catch((error) => console.error(error));
    } else {
      toast
        .promise(
          createUserType(data),
          {
            loading: "Loading",
            success: "User Type Created Succesfully",
            error: (err) => {
              console.log(err);
              return "Something went wrong.";
            },
          },
          {
            id: "login",
          },
        )
        .then(async (response) => {
          reset();
        })
        .catch((error) => console.error(error));
    }
  };

  //   <CRow className="mb-3">
  //   <CFormLabel htmlFor="image" className="col-sm-3 col-form-label">
  //     Image:
  //   </CFormLabel>
  //   <CCol sm={8}>
  //     <CFormInput
  //       type="file"
  //       accept="image/*"
  //       id="image"
  //       {...register("image")}
  //     />
  //     {errors.image?.message && (
  //       <p className="error_text">{errors.image?.message}</p>
  //     )}
  //   </CCol>
  // </CRow>

  const onError = (err) => {
    console.error(err);
  };

  return (
    <CCard className="mb-4">
      <CCardBody>
        <CRow>
          <CCol sm={5}>
            <h4 id="traffic" className="card-title mb-0">
              {userId ? "Edit User Type" : "Create User Type"}
            </h4>
          </CCol>
          <CCol sm={7} className="d-none d-md-block"></CCol>
        </CRow>
        <hr />
        <br />
        <CForm onSubmit={handleSubmit(onSubmit, onError)}>
          <CRow>
            <CCol sm={6}>
              <CRow className="mb-3">
                <CFormLabel htmlFor="name" className="col-sm-3 col-form-label">
                  Name:
                </CFormLabel>
                <CCol sm={8}>
                  <CFormInput type="text" id="name" {...register("name")} />
                  {errors.name?.message && <p className="error_text">{errors.name?.message}</p>}
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CFormLabel htmlFor="userType" className="col-sm-3 col-form-label">
                  User Type:
                </CFormLabel>
                <CCol sm={8}>
                  <CFormSelect
                    aria-label="Please Select User Type"
                    options={[
                      { label: "Admin", value: "admin" },
                      { label: "CVIT", value: "cvit" },
                      { label: "Driver", value: "driver" },
                      { label: "Store", value: "store" },
                      { label: "Others", value: "others" },
                    ]}
                    {...register("userType")}
                    onChange={(e) =>
                      setValue("userType", e.target.value, {
                        shouldValidate: true,
                      })
                    }
                  />
                  {errors.userType?.message && (
                    <p className="error_text">{errors.userType?.message}</p>
                  )}
                </CCol>
              </CRow>

              {/* <CRow className="mb-3">
                <CFormLabel htmlFor="image" className="col-sm-3 col-form-label">
                  Image:
                </CFormLabel>
                <CCol sm={8}>
                  <CFormInput
                    type="file"
                    accept="image/*"
                    id="image"
                    {...register("image")}
                  />
                  {errors.image?.message && (
                    <p className="error_text">{errors.image?.message}</p>
                  )}
                </CCol>
              </CRow> */}
            </CCol>
            <CCol sm={6}>
              <CRow className="mb-3">
                <CFormLabel htmlFor="permission" className="col-sm-3 col-form-label">
                  Permission:
                </CFormLabel>
                <CCol sm={8}>
                  <CFormCheck
                    id="manage_user_type"
                    label="Manage User Type"
                    {...register("permission.manage_user_type")}
                  />
                  <CFormCheck
                    id="manage_user"
                    label="Manage User"
                    {...register("permission.manage_user")}
                  />
                  <CFormCheck
                    id="manage_store"
                    label="Manage Store"
                    {...register("permission.manage_store")}
                  />
                  <CFormCheck
                    id="manage_plastic_seal"
                    label="Manage Plastic Seal"
                    {...register("permission.manage_plastic_seal")}
                  />
                  <CFormCheck
                    id="create_booking_order"
                    label="Create Booking Orders"
                    {...register("permission.create_booking_order")}
                  />
                  <CFormCheck
                    id="new_booking_orders"
                    label="New Booking Orders"
                    {...register("permission.new_booking_orders")}
                  />
                  <CFormCheck
                    id="manage_plastic_seal"
                    label="Manage Booking Orders"
                    {...register("permission.manage_booking_orders")}
                  />

                  <CFormCheck
                    id="manage_route"
                    label="Manage Route"
                    {...register("permission.manage_route")}
                  />
                  <CFormCheck
                    id="manage_trip"
                    label="Manage Trip"
                    {...register("permission.manage_trip")}
                  />
                  <CFormCheck
                    id="report_issue"
                    label="Report Issue"
                    {...register("permission.report_issue")}
                  />

                  <CFormCheck
                    id="check_delivery_status"
                    label="Check Delivery Status"
                    {...register("permission.check_delivery_status")}
                  />
                  <CFormCheck
                    id="tranfer_package"
                    label="Tranfer Package"
                    {...register("permission.tranfer_package")}
                  />
                  <CFormCheck id="settings" label="Settings" {...register("permission.settings")} />
                </CCol>
              </CRow>
            </CCol>

            <CRow className="mt-5">
              <CCol sm={1}></CCol>
              <CCol sm={10}>
                <div className="d-grid gap-6">
                  <CButton type="submit" color="primary">
                    Submit
                  </CButton>
                </div>
              </CCol>
              <CCol sm={1}></CCol>
            </CRow>
          </CRow>
        </CForm>
      </CCardBody>
    </CCard>
  );
};

export default CreateUserTypeForm;
