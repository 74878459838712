import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from "@coreui/icons";

import { AppBreadcrumb } from "./index";
import { AppHeaderDropdown } from "./header/index";
// import {logo} from 'src/assets/brand/logo'
import logo from "src/assets/images/logo-new.png";
import demoAvatar from "src/assets/images/avatars/avatar.png";

const AppHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <img src={demoAvatar} height={48} alt="Logo" />
          {/* <CIcon icon={logo} height={48} alt="Logo"/> */}
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          {/* <CNavItem>
            <CNavLink to="/dashboard" component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        <CHeaderNav>
          {/* <CNavItem>
            <CNavLink href="#" className='notification-bar'>
            <div className = "icons">
                <div className = "notification">
                  <a href = "#">
                  <div className = "notBtn" href = "#">
                    <div className = "number">2</div>
                    <CIcon icon={cilBell} size="lg" />
                      <div className = "box">
                        <div className = "display">
                          <div className = "nothing"> 
                            <i className="fas fa-child stick"></i> 
                            <div className = "cent">Looks Like your all caught up!</div>
                          </div>
                          <div className = "cont">
                            <div className = "sec new">
                              <a href = "/user-activity">
                              <div className = "profCont">
                              <img className = "profile" src = "https://c1.staticflickr.com/5/4007/4626436851_5629a97f30_b.jpg"/>
                                </div>
                              <div className = "txt">James liked your post: "Pure css notification box"</div>
                              <div className = "txt sub">11/7 - 2:30 pm</div>
                              </a>
                            </div>
                            <div className = "sec new">
                              <a href = "/user-activity">
                              <div className = "profCont">
                              <img className = "profile" src = "https://obamawhitehouse.archives.gov/sites/obamawhitehouse.archives.gov/files/styles/person_medium_photo/public/person-photo/amanda_lucidon22.jpg?itok=JFPi8OFJ"/>
                                </div>
                              <div className = "txt">Annita liked your post: "Pure css notification box"</div>
                              <div className = "txt sub">11/7 - 2:13 pm</div>
                              </a>
                            </div>
                            
                            <div className = "sec">
                              <a href = "/user-activity">
                              <div className = "profCont">
                              <img className = "profile" src = "https://c1.staticflickr.com/4/3725/10214643804_75c0b6eeab_b.jpg"/>
                                </div>
                              <div className = "txt">Madison liked your post: "Pure css notification box"</div>
                              <div className = "txt sub">11/6 - 4:04 pm</div>
                              </a>
                            </div>
                            <div className = "sec">
                              <a href = "/user-activity">
                              <div className = "profCont">
                              <img className = "profile" src="https://upload.wikimedia.org/wikipedia/commons/5/52/NG_headshot_white_shirt_square_Jan18.jpg"/>
                                </div>
                              <div className = "txt">Ted liked your post: "Pure css notification box"</div>
                              <div className = "txt sub">11/6 - 10:37 am</div>
                              </a>
                            </div>
                            <div className = "sec">
                              <a href = "/user-activity">
                              <div className = "profCont">
                              <img className = "profile" src = "https://upload.wikimedia.org/wikipedia/commons/d/dd/Pat-headshot-square.jpg"/>
                                </div>
                              <div className = "txt">Tommas liked your post: "Pure css notification box"</div>
                              <div className = "txt sub">11/5 - 7:30 pm</div>
                              </a>
                            </div>
                            <div className = "sec">
                              <a href = "/user-activity">
                              <div className = "profCont">
                              <img className = "profile" src = "//c1.staticflickr.com/1/185/440890151_54c5b920b0_b.jpg"/>
                                </div>
                              <div className = "txt">Jerimaiah liked your post: "Pure css notification box"</div>
                              <div className = "txt sub">11/5 - 1:34 pm</div>
                              </a>
                            </div>
                            <div className = "sec">
                              <a href = "/user-activity">
                              <div className = "profCont">
                              <img className = "profile" src = "//c2.staticflickr.com/4/3397/3585544855_28442029a5_z.jpg?zz=1"/>
                                </div>
                              <div className = "txt">Debra liked your post: "Pure css notification box"</div>
                              <div className = "txt sub">11/5 - 10:20 am</div>
                              </a>
                            </div>
                        </div>
                        </div>
                    </div>
                  </div>
                    </a>
              </div>
              </div>
            </CNavLink>
          </CNavItem> */}
          {/* <CNavItem>
            <CNavLink href="#">
            </CNavLink>
          </CNavItem> */}
          {/* <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilEnvelopeOpen} size="lg" />
            </CNavLink>
          </CNavItem> */}
        </CHeaderNav>

        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      {/* <CHeaderDivider/>
      <CContainer fluid>
        <AppBreadcrumb/>
      </CContainer> */}
    </CHeader>
  );
};

export default AppHeader;
