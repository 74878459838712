import ApiClient from "./interceptor";

const createProject = async (input) => {
  return ApiClient.post("/projects", input);
};

const shareProject = async (input) => {
  return ApiClient.post("/share", input);
};

const deleteProject = async (id) => {
  return ApiClient.delete("/projects/" + id);
};

const deleteSharedProject = async (id) => {
  return ApiClient.delete("/share/" + id);
};

const getAllProject = async (parmas) => {
  return ApiClient.get("/projects", { params: parmas });
};

const getAllSharedProject = async (parmas) => {
  return ApiClient.get("/share", { params: parmas });
};

const getSharedProject = async (parmas) => {
  return ApiClient.get("/share/to", { params: parmas });
};

const getAllProjectByUserId = async (id) => {
  return ApiClient.get("/projects/user/" + id);
};

const getProjectById = async (id) => {
  return ApiClient.get("/projects/" + id);
};

const updateProject = async ({ id, data }) => {
  return ApiClient.patch("/projects/" + id, data);
};

export {
  createProject,
  deleteProject,
  getAllProject,
  getAllProjectByUserId,
  getProjectById,
  updateProject,
  shareProject,
  getAllSharedProject,
  getSharedProject,
  deleteSharedProject,
};
