import React from "react";
import {
  CAvatar,
  CContainer,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
  CNavLink,
} from "@coreui/react";
import { cilBell, cilEnvelopeOpen, cilList, cilLockLocked } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

import avatar from "./../../assets/images/avatars/avatar.png";
import { Link } from "react-router-dom";
import AppBreadcrumb from "../AppBreadcrumb";
import { useNavigate, Navigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const AppHeaderDropdown = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const handleLogout = () => {
    localStorage.removeItem("auth");
    queryClient.resetQueries();
    navigate("/", {
      replace: true,
    });
  };

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={avatar} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
        {/* <CDropdownItem href="/user-activity">
          <CIcon icon={cilLockLocked} className="me-2" />
          Activity Log
        </CDropdownItem> */}
        <CDropdownItem href="#" onClick={handleLogout}>
          <CIcon icon={cilLockLocked} className="me-2" />
          Log Out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
