import { FilterTypes } from "../commontypes";
import { removeHiddenRows } from "./commonHelper";

const handleCrossTabulation = (
  columns,
  rows,
  selectedColumns,
  hiddenData,
  setHiddenData,
  filterTypes,
) => {
  const _hiddenData = { ...hiddenData };
  const _selectedColumns = selectedColumns[filterTypes];
  let result = rows.filter((row) => {
    for (let i = 0; i < _selectedColumns.length; i++) {
      const column = _selectedColumns[i];
      const expectedArray = column.expected?.split(",").map((item) => item?.trim()?.toLowerCase());
      const unexpectedArray = column.unexpected
        ?.split(",")
        .map((item) => item?.trim()?.toLowerCase());

      if (row[columns[column.source]] !== undefined) {
        if (row[columns[column.source]] > column.min && row[columns[column.source]] < column.max) {
          const value = String(row[columns[column.target]]);
          let commaSeparatedValues = [];

          if (value.includes(",")) {
            commaSeparatedValues = value?.split(",").map((item) => item.trim().toLowerCase());
          } else {
            commaSeparatedValues = [value?.trim()?.toLowerCase()];
          }

          if (
            (expectedArray?.length > 0 &&
              !expectedArray.some((expected) => commaSeparatedValues?.includes(expected))) ||
            (unexpectedArray?.length > 0 &&
              unexpectedArray?.some((unexpected) => commaSeparatedValues?.includes(unexpected)))
          ) {
            return true;
          }
        }
      }
    }
  });
  _hiddenData[FilterTypes.CROSS_TABULATION] = [
    ..._hiddenData[FilterTypes.CROSS_TABULATION],
    ...result,
  ];
  console.log({ rows });
  setHiddenData(_hiddenData);
  result = removeHiddenRows(rows, _hiddenData);

  return { _hiddenData, result };
};

export { handleCrossTabulation };
