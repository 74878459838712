import axios from "axios";
import { API_URL } from "../enviroment/enviroment";
import ApiClient from "./interceptor";

// export const getUserTypeList = ({offset, limit}) => {
//   return {
//     api() {
//       const params = new URLSearchParams({
//         offset: offset ? offset : '0',
//         limit: limit ? limit : '10',
//       });
//       return ApiClient.get(`${API_URL}/users?${params.toString()}`, {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       }).then((result) => result.data);
//     },
//     getKey() {
//       return ['user', 'list']
//     }
//   }
// };

const createUserType = async (input) => {
  const authToken = localStorage.getItem("auth");
  return axios
    .post(`${API_URL}/user-type`, input, {
      headers: {
        "Content-Type": "application/json",
        authorization: `bearer ${JSON.parse(authToken)}`,
      },
    })
    .then((result) => {
      return result.data;
    });
};

const updateUserType = async (input, id) => {
  const authToken = localStorage.getItem("auth");
  return axios
    .patch(`${API_URL}/user-type/${id}`, input, {
      headers: {
        "Content-Type": "application/json",
        authorization: `bearer ${JSON.parse(authToken)}`,
      },
    })
    .then((result) => {
      return result.data;
    });
};

const getUserTypeList = async ({ limit, page }) => {
  const authToken = localStorage.getItem("auth");
  const params = new URLSearchParams({
    page: page ? page : "1",
    limit: limit ? limit : "10",
  });
  return axios
    .get(`${API_URL}/user-type?${params}`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `bearer ${JSON.parse(authToken)}`,
      },
    })
    .then((result) => {
      return result.data;
    });
};

const getAllUserTypeList = async () => {
  const authToken = localStorage.getItem("auth");

  return axios
    .get(`${API_URL}/user-type`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `bearer ${JSON.parse(authToken)}`,
      },
    })
    .then((result) => {
      return result.data;
    });
};

const getSingleUserType = async (id) => {
  const authToken = localStorage.getItem("auth");

  return axios
    .get(`${API_URL}/user-type/${id}`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `bearer ${JSON.parse(authToken)}`,
      },
    })
    .then((result) => {
      return result.data;
    });
};

export { createUserType, getUserTypeList, getSingleUserType, updateUserType, getAllUserTypeList };
